import {useEffect, useState} from "react";

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showModal, setShowModal] = useState(!navigator.onLine);
    const [message, setMessage] = useState(
        navigator.onLine ? "" : "Connection Lost"
    );

    const updateOnlineStatus = () => {
        const online = navigator.onLine;

        setIsOnline(online);
        if (online) {
            setMessage("Connection Restored");
            setTimeout(() => {
                setShowModal(false);
            }, 10000);
        } else {
            setMessage("Connection Lost");
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        window.addEventListener("online", updateOnlineStatus);
        window.addEventListener("offline", updateOnlineStatus);
        return () => {
            window.removeEventListener("online", updateOnlineStatus);
            window.removeEventListener("offline", updateOnlineStatus);
        };
    }, []);

    return {isOnline, showModal, message, closeModal};
};

export default useNetworkStatus;
