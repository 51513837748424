import React from "react";
import {useTranslation} from "react-i18next";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";
import CloseIcon from '@mui/icons-material/Close';

export const OfflineModal = ({show, closeModal, message}) => {
    const {t} = useTranslation();
//   const message = "Connection st"; // Changed to lowercase 'message' to follow naming conventions and avoid confusion with JSX elements

    return (
        <div
            className={`fixed left-0 bottom-0 mb-4 duration-200 gap-2 items-center flex ml-4 p-3 px-4 bg-white border border-gray-300 rounded-lg shadow-lg z-[200] transition-transform ${
                show ? " bottom-0" : "bottom-[-100%]"
            }`}
        >
            {message === "Connection Lost" ? (
                <WifiOffIcon className="text-gray-500"/>
            ) : (
                <WifiIcon className="text-green-300"/>
            )}
            <div>
                <h2>
                    {message === "Connection Lost"
                        ? t("NetWorkStatus.connectionLost")
                        : t("NetWorkStatus.connectionRestored")}
                </h2>
            </div>
            {message !== "Connection Lost" &&
                <button onClick={closeModal}><CloseIcon className=" scale-75 text-gray-500 "/></button>}
        </div>
    );
};
