import {React, useState, useEffect} from "react";
import Modal from "../ui/Modal";
import Topbar from "../ui/Topbar";
import CloseIcon from "@mui/icons-material/Close";
import "./Loading.css";
import axios from "axios";
import {toast} from "react-toastify";

function SuperHostAiAnalyse({
                                setTitleAndDescription,
                                TitleAndDescription,
                                setIsModalOpen,
                                IsModalOpen,
                                BienInfo,
                            }) {
    const [sections, setSections] = useState(null);
    const [IsLoading, setIsLoading] = useState(false);

    const parseSections = (text) => {
        const sections = {};
        const sectionNames = [
            "POINTS FORTS",
            "POINTS FAIBLES",
            "AMÉLIORATIONS SUGGÉRÉES",
            "DESCRIPTION",
        ];

        sectionNames.forEach((name) => {
            const regex = new RegExp(
                `\\[DEBUT ${name}\\](.*?)\\[FIN ${name}\\]`,
                "s"
            );
            const match = text.match(regex);
            if (match) {
                sections[name] = match[1].trim();
            }
        });

        return sections;
    };

    const renderSection = (title, content) => {
        const items = content
            .split("\n")
            .map((item, index) => {
                // Remove leading hyphen and any extra whitespace
                const cleanedItem = item.replace(/^\s*-\s*/, '').trim();
                return <li key={index} className=" px-3 list-disc list-inside">{cleanedItem}</li>;
            });

        return (
            <div key={title} className="section">
                <h2 className="pt-3 font-semibold text-sm">{title}</h2>
                <ul className="list-disc list-inside">{items}</ul>
            </div>
        );
    };


    async function AnalyesDsecription() {
        setIsLoading(true);

        // const rawText = `
        // [DEBUT POINTS FORTS]
        // - Emplacement central et très prisé dans la banlieue nord de Tunis.
        // - Résidence moderne et charmante.
        // - Mezzanine offrant un espace supplémentaire.
        // - Décoration moderne et lumineuse.
        // - Petit jardin pour se détendre au soleil.
        // [FIN POINTS FORTS]

        // [DEBUT POINTS FAIBLES]
        // - Manque de détails sur les équipements et les caractéristiques de la propriété.
        // - Absence d'informations sur les commodités environnantes.
        // - Pas de mention des activités ou attractions à proximité.
        // [FIN POINTS FAIBLES]

        // [DEBUT AMÉLIORATIONS SUGGÉRÉES]
        // - Inclure une liste détaillée des équipements disponibles tels que la cuisine, le WIFI, la télévision, le parking, etc.
        // - Mentionner les services à proximité tels que les restaurants, les magasins, les transports en commun.
        // - Mettre en avant les activités de loisirs ou les sites touristiques à découvrir dans les environs.
        // [FIN AMÉLIORATIONS SUGGÉRÉES]

        // [DEBUT DESCRIPTION]
        // Plongez au cœur de la banlieue nord de Tunis dans ce charmant chalet situé dans une résidence moderne très prisée. Laissez-vous séduire par la luminosité qui inonde l'espace, mettant en valeur la mezzanine offrant un espace supplémentaire pour se détendre. La décoration moderne crée une atmosphère chaleureuse et accueillante, tandis que le petit jardin invite à la détente au soleil. Profitez des équipements de qualité tels qu'une cuisine entièrement équipée, le WIFI, une télévision, un parking gratuit, un lave-linge, une cheminée, un piano, et un accès au lac à proximité. À quelques pas, vous trouverez une variété de restaurants, de magasins et de transports en commun pour explorer facilement la région. Ne manquez pas l'occasion de découvrir les activités de loisirs et les sites touristiques à proximité. Réservez dès maintenant cet endroit idéal pour votre prochain séjour et vivez une expérience inoubliable à Tunis.
        // [FIN DESCRIPTION]
        // `;

        // const parsedSections = parseSections(rawText);
        // setSections(parsedSections);
        try {
            const response = await axios.post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/AnalyseDescription",
                {
                    MessageAAnalyser: TitleAndDescription?.description,
                    InformationsSurLeBien: BienInfo,
                }
            );
            const parsedSections = parseSections(response.data);
            setSections(parsedSections);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
            console.error("Login failed. Error:", error.message);
            throw error;
        }
    }

    const splitContentWithLineBreaks = (content) => {
        return content.split('.').map((sentence, index) => (
            <span key={index}>
        {sentence.trim()}
                {index < content.split('.').length - 1 && '.'}
                <br/>
      </span>
        ));
    };

    return (
        <div>
            <Modal
                isFullPage={true}
                isOpen={IsModalOpen}
                Style={"  h-[100%] lg:max-h-[70%] lg:w-[60%] w-full"}
                onClose={() => setIsModalOpen(false)}
            >
                <div
                    className="  w-full rounded-lg px-[1rem] h-full  flex flex-col  lg:px-5  py-5 bg-white overflow-auto ">
                    {IsLoading ? (
                        <div className=" flex items-center justify-center  flex-col gap-3 w-full h-full">
                            <div className=" flex items-center">
                                <div className="loader l1"></div>
                                <div className="loader l2"></div>
                                <div className="loader l3"></div>
                                <div className="loader l4"></div>
                                <div className="loader l5"></div>
                                <div className="loader l6"></div>
                                <div className="loader l7"></div>
                            </div>
                            {/* <h1 className=" font-medium text-sm">Analysing</h1> */}
                        </div>
                    ) : (
                        <>
              <span className=" w-full absolute top-0 right-0 ">
                <Topbar
                    onClick={() => setIsModalOpen(false)}
                    text={" Your new HOST Best Friend"}
                />
              </span>
                            <span
                                className={`   hidden lg:block  items-center  text-center font-semibold bg-white justify-center py-3 w-full absolute top-0 right-0 `}
                            >
                Your new Host Best Friend
                <span
                    onClick={() => setIsModalOpen(false)}
                    className="cursor-pointer  absolute top-3 left-4"
                >
                  <CloseIcon/>{" "}
                </span>
              </span>
                            {!sections ? (
                                <div
                                    className="  py-9 font-semibold text-lg px-5 pt-20 items-center gap-5  justify-center h-full text-center w-full  flex flex-col">
                                    Experience the magic of our SuperHost AI as it analyzes your
                                    description and enhances it to be more captivating and
                                    irresistible.
                                    <button
                                        onClick={AnalyesDsecription}
                                        className=" px-6 py-3 mt-1 w-fit rounded-md bg-main text-white"
                                    >
                                        Analyes
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <div className=" py-14">
                                        {Object.entries(sections).map(([title, content]) =>
                                            title === "DESCRIPTION" ? (
                                                <div
                                                    key={title}
                                                    className="description mt-8 p-4 border rounded-lg"
                                                >
                                                    <h2 className="font-semibold pb-1">
                                                        Description suggérée :
                                                    </h2>
                                                    <p>{splitContentWithLineBreaks(content)}</p>
                                                </div>
                                            ) : (
                                                renderSection(title, content)
                                            )
                                        )}
                                        <span
                                            className={`border-t-[1px] w-full gap-5 font-semibold flex pt-3 py-2 bg-white  rounded-b-lg items-center justify-center absolute bottom-0 right-0`}
                                        >
                      <button
                          onClick={AnalyesDsecription}
                          className=" px-4 py-2 rounded-md  border-[1px] "
                      >
                       Régénérer
                      </button>
                      <button
                          onClick={() => {
                              setTitleAndDescription((prev) => ({
                                  ...prev,
                                  description: sections["DESCRIPTION"] || "",
                              }));
                              setIsModalOpen(false);
                          }}
                          className=" px-4 py-2 rounded-md bg-main text-white"
                      >
                       Définir comme description
                      </button>
                    </span>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default SuperHostAiAnalyse;
