export var gouvernorats = {
    "Ariana": ["Ariana Essoughra", "Ariana Ville", "Autres Villes", "Borj Louzir", "Charguia 1", "Charguia 2",
        "Chotrana", "Chotrana 1", "Chotrana 2", "Chotrana 3", "Cite Ennkhilet", "Cite Ennasr 1", "Cite Ennasr 2",
        "Cite Hedi Nouira", "Dar Fadhal", "El Menzah 5", "El Menzah 6", "El Menzah 7", "El Menzah 8", "Ettadhamen",
        "Ettadhamen", "Ghazela", "Jardins El Menzah", "Kalâat Andalous", "La Soukra", "Les Jardins El Menzah 1",
        "Les Jardins El Menzah 2", "Mnihla", "Nouvelle Ariana", "Raoued", "Riadh Andalos", "Sidi Thabet",
    ],

    "Ben Arous": ["Autres Villes", "Ben Arous", "Borj Cedria", "Boumhel", "El Mourouj", "El Mourouj", "El Mourouj 1",
        "El Mourouj 3", "El Mourouj 4", "El Mourouj 5", "El Mourouj 6", "Ezzahra", "Fouchana", "Hammam Chott", "Hammam Lif",
        "Medina Jedida", "Mohamedia", "Mornag", "Mégrine", "Radés", "Sidi Rzig"
    ],


    "Bizerte": ["Autres Villes", "Bizerte", "Bizerte Nord", "Bizerte Sud", "Djoumime", "El Alia", "Ghar El Melh", "Ghezala", "Mateur",
        "Menzel Bourguiba", "Menzel Jemil", "Ras Jebel", "Sejenane", "Tinja", "Utique", "Zarzouna"
    ],

    "Beja": ["Amdoun", "Autres Villes", "Beja Nord", "Béja Sud", "El Ksar", "Goubellat",
        "Medjez el Bab", "Nefza", "Testour", "Thibar", "Téboursouk"
    ],

    "Gabes": ["Autres Villes", "El Hamma", "Gabés", "Gabés Médina", "Gabés Ouest", "Gabés Sud", "Ghanouch", "Mareth", "Matmata",
        "Métouia", "Nouvelle Matmata"
    ],

    "Gafsa": ["Autres Villes", "Belkhir", "El Guettar", "El Ksar", "Gafsa Nord", "Gafsa Sud", "Mdhila", "Métlaoui", "Oum El Araies",
        "Redeyef", "Sened", "Sidi Aïch"
    ],

    "Jendouba": ["Ain Draham", "Autres Villes", "Balta Bou Aouane", "Bou Salem", "Fernana", "Ghardimaou", "Jendouba Nord",
        "Oued Meliz", "Tabarka"
    ],

    "Kairouan": ["Autres Villes", "Bouhajla", "Chebika", "Echrarda", "El Alâa", "El Ouslatia", "Haffouz", "Hajeb El Ayoun",
        "Kairouan Nord", "Kairouan Sud", "Nasrallah", "Sbikha"
    ],

    "Kasserine": ["Autres Villes", "Djedeliane", "El Ayoun", "Ezzouhour", "Foussana", "Fériana", "Hassi Ferid",
        "Hidra", "Kasserine Nord", "Kasserine Sud", "Majel Bel Abbès", "Sbeïtla", "Sbiba", "Thala"
    ],

    "Kebili": ["Autres Villes", "Douz Nord", "Douz Sud", "Faouar", "Kébili", "Kébili Nord", "Kébili Sud", "Souk Lahad"],

    "La Manouba": ["Autres Villes", "Borj El Amri", "Denden", "Djedeida", "Douard Hicher", "El Battan", "La Manouba",
        "Manouba Ville", "Menzel El Habib", "Mornaguia", "Oued Elil", "Tebourba"
    ],

    "Le Kef": ["Autres Villes", "Dahmani", "Djerissa", "El Ksour", "Es Sers", "Kalâat Khasbah", "Kalâat Snan", "Kef Est",
        "Kef Ouest", "Nebeur", "Sakiet Sidi Youssef", "Tajerouine"
    ],

    "Mahdia": ["Autres Villes", "Bou Merdés", "Chebba", "Chorbane", "El Jem", "Essouassi", "Hebira", "Ksour Essef", "Mahdia",
        "Méllouléche", "Ouled Chamekh", "Sidi Alouane"
    ],

    "Monastir": ["Autres Villes", "Bekalta", "Bembla", "Beni Hassen", "Jemmal", "Ksar Hellal", "Ksibet El Médiouni",
        "Moknine", "Monastir", "Ouerdanine", "Sahline", "Sayada Lamta Bou Hajar", "Téboulba", "Zéramdine"
    ],

    "Medenine": ["Autres Villes", "Ben Gardane", "Beni Khedech", "Djerba Ajim", "Djerba Houmt Souk", "Djerba Midoun",
        "Médenine", "Médenine Nord", "Médenine Sud", "Sidi Makhloulf", "Zarzis"
    ],

    "Nabeul": ["Autres Villes", "Bou Argoub", "Béni Khalled", "Béni Khiar", "Dar Châabane El Fehri", "El Haouaria", "El Mida",
        "Grombalia", "Hammam Ghezèze", "Hammamet", "Korba", "Kélibia", "Nabeul", "Menzel Bouzelfa",
        "Menzel Temime", "Mrezga", "Nabeul", "Soliman", "Takelsa"
    ],

    "Sfax": ["Autres Villes", "Bir Ali Ben Khalifa", "El Amra", "El Hencha", "Ghraiba", "Jebiniana", "Kerkennah", "Mahrès", "Menzel Chaker",
        "ROUTE SALTANIA", "Route El Afrane", "Route El Ain", "Route GREMDA", "Route MANZEL CHAKER", "Route MHARZA", "Route Mehdia", "Route SOKRA",
        "Route TANIOUR", "Route Tunis", "Route de GABES", "Route de L aéroport", "Sakiet Eddaïer", "Sakiet Ezzit", "Sfax Médina", "Sfax Ville",
        "Skhira", "Thyna"
    ],

    "Sidi Bouzid": ["Autres Villes", "Bir El Hafey", "Cebbala Ouled Asker", "Jilma", "Meknassy", "Menzel Bouzaiane", "Mezzouna",
        "Ouled Haffouz", "Regueb", "Sidi Ali Ben Aoun", "Sidi Bouzid", "Sidi Bouzid Est", "Sidi Bouzid Ouest", "Souk Jedid"
    ],

    "Siliana": ["Autres Villes", "Bargou", "Bou Arada", "El Aroussa", "El Krib", "Gaâfour", "Kesra", "Makthar", "Rouhia",
        "Sidi Bou Rouis", "Siliana", "Siliana Nord", "Siliana Sud"
    ],

    "Sousse": ["Akouda", "Autres Villes", "Bouficha", "Chatt mariem", "Enfidha", "Hammam Sousse", "Hergla", "Kalaâ Kebira", "Kalaâ Sghira",
        "Kantaoui", "Khzema", "Kondar", "M Saken", "Sahloul", "Sidi Bou Ali", "Sidi El Héni", "Sousse", "Sousse Jawhara", "Sousse Médina",
        "Sousse Riadh", "Sousse Sidi Abdelhamid", "Zaouit Ksibat Thrayett"
    ],

    "Tataouine": ["Autres Villes", "Bir Lahmar", "Dehiba", "Ghomrassen", "Remada", "Smâr", "Tataouine", "Tataouine Nord", "Tataouine Sud"],

    "Tozeur": ["Autres Villes", "Degache", "Hazoua", "Nefta", "Tameghza", "Tozeur"],

    "Tunis": ["Agba", "Ain Zaghouan Nord", "Ain Zaghouan Sud", "Ain Zaghouan", "Autres Villes", "Beb Souika", "Bellevue", "Carthage",
        "Centre Urbain Nord", "Centre Ville Lafayette", "Cité El Khadhra", "Cité Olympique", "Djebel Jelloud", "El Kabaria", "El Manar 1",
        "El Manar 2", "El Menzah 1", "El Menzah 4", "El Menzah 9", "El Omrane", "El Omrane Supérieur", "El Ouardia", "Ettahrir", "Ezzouhour",
        "Gammarth", "Hraïria", "Jardins de Carthage", "Khaznadar", "Kheireddine Pacha", "Ksar Said", "Laouina", "La Goulette", "La Marsa",
        "Lac 1", "Lac 2", "Le Bardo", "Le Kram", "Manar", "Menzah", "Monflery", "Montplaisir", "Mutuelleville", "Médina", "Sidi Bou Said",
        "Sidi Daoud", "Sidi El Béchir", "Sidi Hassine", "Séjoumi", "Tunis", "Tunis Belvedere"
    ],

    "Zaghouan": ["Autres Villes", "Bir Mchergua", "El Fahs", "En Nadhour", "Ez Zeriba", "Saouaf", "Zaghouen"],


};