import React, {useEffect, useState} from "react";
import getProps from "../../../assest/ProposeData";
import {proprse} from "../../../assest/ImgProposeData";

import {toast} from "react-toastify";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

function MesEquipements({proposee, setProps, ID_Bien, Slog}) {
    const {id} = useParams();
    const {t} = useTranslation();
    const Propos = getProps(t);
    const [isPropsLoading, setisPropsLoading] = useState();

    const UpdateProposee = async (value, add) => {
        setisPropsLoading(value);
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateCaracteristique`,
                {
                    Bien: {
                        ID_Bien: ID_Bien,
                        ID_Proprietaire: UserId,
                    },
                    Caracteristiques: {
                        [value]: add ? 1 : 0,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setisPropsLoading(null);
            setProps((prevProp) => ({
                ...prevProp,
                [value]: add ? 1 : 0,
            }));
            toast.success(
                t("Msgs.Updatesuccessful", {text: t("Labels.Equipment")})
            );
        } catch (error) {
            setisPropsLoading(null);
            throw new Error(`Error fetching geocoding data: ${error.message}`);
        }
    };


    return (
        <div className=" pt-4  gap-4 flex flex-col ">
            <h1 className=" font-semibold"> {t("Labels.MyEquipment")} </h1>
            <div className="grid grid-cols-1 gap-3">
                {Propos.map((item) => {
                    const isActive =
                        proposee && proposee[item?.value] == 1;

                    return (
                        <div
                            key={item.value} // Add a unique key to each mapped element
                            className={`  duration-200 border-b-2 py-[1.2rem] w-full lg:w-[50%] flex justify-between items-center`}
                        >
                            <div
                                className={`${
                                    isActive ? "text-sec" : "text-gray-400"
                                } flex items-center gap-5 justify-start`}
                            >
                                <img
                                    className="h-[2rem]"
                                    src={isActive ? item.Active : item.img}
                                    alt={item.title}
                                />
                                {item.title}
                            </div>
                            <div className="gap-2 flex">
                                <button
                                    disabled={isPropsLoading == item?.value}
                                    onClick={() => UpdateProposee(item?.value, false)}
                                    className={` ${
                                        isPropsLoading == item?.value ? " opacity-50" : " "
                                    } rounded-md  w-[2.3rem] h-[2.3rem] ${
                                        proposee && proposee[item?.value] == 0
                                            ? "bg-sec text-white"
                                            : "text-gray-400 border-solid border-[1px]"
                                    }`}
                                >
                                    {t("Actions.No")}
                                </button>
                                <button
                                    disabled={isPropsLoading == item?.value}
                                    onClick={() => UpdateProposee(item?.value, true)}
                                    className={` ${
                                        isPropsLoading == item?.value ? " opacity-50" : " "
                                    }  rounded-md w-[2.3rem] h-[2.3rem]  ${
                                        isActive
                                            ? "bg-sec text-white"
                                            : "text-gray-400 border-solid border-[1px]"
                                    }`}
                                >
                                    {t("Actions.Yes")}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MesEquipements;
