import React from 'react'

function RouteLoading() {
    return (
        <div className=" h-full w-full fixed top-0 right-0 bg-white  z-[100] flex items-center justify-center ">
    <span className=" scale-[.08] lg:scale-[.04] w-full h-full  flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="40"
            cy="100"
        >
          <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
          ></animate>
        </circle>
        <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="100"
            cy="100"
        >
          <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
          ></animate>
        </circle>
        <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="160"
            cy="100"
        >
          <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
          ></animate>
        </circle>
      </svg>
    </span>
        </div>
    )
}

export default RouteLoading