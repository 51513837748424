// CustomDateCellWrapper.js
import React from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const CustomDateCellWrapper = ({
                                   value,
                                   children,
                                   selectedSlots,
                                   isBlokedDate,
                                   isEventOnDay,
                                   IsselectBeinLoading,
                                   getNewPriceForDate,
                                   Prices,
                               }) => {
    const selectedDate = moment(value).format("YYYY-MM-DD");
    const isDateSelected = selectedSlots.includes(selectedDate);
    const isPastDate = moment(value).isBefore(moment().startOf("day"));

    const cellStyles = {
        position: "relative",
        padding: "5px",
        backgroundColor:
            !isPastDate && isDateSelected
                ? "#FF5A5F"
                : isPastDate
                    ? "#e1e1e1"
                    : isBlokedDate(value)
                        ? "#F3F3F3"
                        : "white",
        border: ".5px solid #ddd",
        borderRadius: "5px",
        color:
            !isPastDate && isDateSelected ? "white" : isPastDate ? "gray" : "black",
        pointerEvents: isPastDate ? "none" : "auto",
    };

    return (
        <div className="flex items-center justify-center w-full h-full">
            <div
                className={`flex flex-col relative justify-between items-start w-full h-full`}
                style={!isEventOnDay(value) ? cellStyles : null}
            >
                {IsselectBeinLoading ? (
                    <Skeleton width={30}/>
                ) : (
                    <div className={`${isBlokedDate(value) ? "line-through" : ""}`}>
                        {moment(value).format("D")}
                    </div>
                )}
                {children}
                {!isEventOnDay(value) &&
                    (getNewPriceForDate(value) ? (
                        !IsselectBeinLoading ? (
                            <span className="text-[.6rem] lg:text-base flex items-center gap-1">
                {getNewPriceForDate(value)}
                                <p className="text-[.5rem] lg:text-xs">TND</p>
              </span>
                        ) : (
                            <Skeleton width={45}/>
                        )
                    ) : !IsselectBeinLoading ? (
                        <span className="text-[.6rem] lg:text-base flex items-center gap-1">
              {parseInt(Prices.Prix)?.toFixed(0)}
                            <p className="text-[.5rem] lg:text-xs">TND</p>
            </span>
                    ) : (
                        <Skeleton width={45}/>
                    ))}
            </div>
        </div>
    );
};

export default CustomDateCellWrapper;
