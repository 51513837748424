import {React, useEffect} from "react";

const Modal = ({isOpen, onClose, children, Style, CloseOnlyFromBtn, isFullPage}) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <div
            className={`duration-150 ${
                isOpen ? "top-[50%]" : " top-[-100%] -z-10 "
            } flex z-[350] fixed h-full w-full   `}
        >
            {/* Overlay for background dimming */}
            <div
                onClick={!CloseOnlyFromBtn ? onClose : null}
                className={`flex fixed h-full w-full   ${
                    isOpen ? "opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
                } right-0 bg-black `}
            ></div>
            {/* the box */}
            <div
                className={`${
                    isOpen ? " opacity-100" : "opacity-0"
                } duration-150 transform flex left-[50%] fixed flex-col items-center  bg-white  translate-x-[-50%] translate-y-[-50%]  justify-between pb-[1rem] rounded-[.5rem]   ${
                    isOpen ? `lg:top-[50%]  ${isFullPage ? '  top-[50%]' : 'top-[60%]'} ` : " top-[200%] lg:-top-[50%]"
                }  ${Style}`}
            >
                {children}
            </div>
        </div>
    );
};

export default Modal;
