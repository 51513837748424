import React, {useState, useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

function UpdateConditionsAnnulation({Conditions, setConditions, Slog}) {
    const [Edit, setEdit] = useState(false);
    const [NewConditions, setNewConditions] = useState();

    const {t} = useTranslation();

    const {id} = useParams();

    useEffect(() => {
        setNewConditions(Conditions);
    }, [Conditions, Edit]);

    async function SaveConditions() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        axios
            .post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateConditionsAnnulation",
                {
                    Slog: id,
                    ID_Proprietaire: UserId,
                    ConditionsAnnulation: NewConditions,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                setConditions(NewConditions);
                setEdit(false);
                toast.success(
                    t("Msgs.Updatesuccessful", {text: t("Labels.TravelConditions")})
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const Items = [
        {
            key: "Ferme",
            label: t("Host.Conditions.ferme"),
            active: Conditions == "ferme" ? true : false,
            // desc: t("Host.Conditions.DescriptionFirm"),
        },
        // {
        //   key: "Strictes",
        //   label: t("Host.Conditions.Strictes"),
        //   // desc: t("Host.Conditions.DescriptionFirm"),
        // },
        // {
        //   key: "Flexible",
        //   label: t("Host.Conditions.Flexible"),
        //   // desc: t("Host.Conditions.DescriptionFlexible"),
        // },
        // {
        //   key: "Moderees",
        //   label: t("Host.Conditions.Moderees"),
        //   // desc: t("Host.Conditions.DescriptionFlexibleOrNonRefundable"),
        // },
    ];

    return (
        <div className={`flex flex-col gap-5  w-full  lg:w-[50%] `}>
            <div className=" flex flex-col gap-2 px-3 ">
                <div className="inputs flex flex-col  w-full gap-3">
                    <h1 className=" font-semibold text-lg">
                        {t("Labels.TravelConditions")}
                    </h1>
                    {!Edit ? (
                        <div
                            className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                            <h2 className="text-left">
                                {t(`Host.Conditions.${Conditions}`)}
                            </h2>
                            <button
                                className="font-semibold  cursor-pointer text-sec"
                                onClick={() => setEdit(true)}
                            >
                                {t("Actions.Edit")}
                            </button>
                        </div>
                    ) : (
                        <div className=" bg-white shadow-lg w-full  border-[1px]  rounded-md flex flex-col gap-3 p-3">
                            <div className="w-full items-center flex flex-col gap-5  ">
                                <div className=" pt-5 flex  w-full flex-col gap-5">
                                    {Items.map((item) => (
                                        <div
                                            className={`z-0 ${item.active ? ' ' : ' opacity-50'}  travelType  px-5    border-solid border-[1px] rounded-md hover:z-30   group relative w-full flex flex-col`}>
                                            <label
                                                htmlFor={item.key}
                                                className={`${item.active ? 'cursor-pointer ' : ''}  py-5 h-full z-20 flex  font-semibold flex-row items-center justify-between  w-full`}
                                            >
                                                <p> {item.label} </p>
                                                <input
                                                    className="accent-sec h-[1rem] w-[1rem]"
                                                    type="radio"
                                                    name="options"
                                                    id={item.key}
                                                    onClick={() => setNewConditions(item.key)}
                                                    checked={item.active || NewConditions === item.key}
                                                />
                                            </label>
                                            {/* <div className=" w-full px-5 text-gray-400 text-sm   group-hover:pt-[1rem] bg-white   duration-150     opacity-0   h-0   group-hover:h-full group-hover:opacity-100 ">
                        {item.desc}
                      </div> */}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                                <button
                                    onClick={() => setEdit(false)}
                                    className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                                >
                                    {t("Actions.Cancel")}
                                </button>
                                <button
                                    onClick={SaveConditions}
                                    className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                                >
                                    {t("Actions.Save")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateConditionsAnnulation;
