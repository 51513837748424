import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import goBack from "../../assest/img/goBack.png";

function GoBack({Titel, isLink, setIsOpen}) {

    const navTo = useNavigate()

    return (
        <span
            className="      pt-[1.6rem]     z-20   mb-[2.5rem]  hidden  lg:flex flex-row items-center  px-[1rem]   gap-5">
    <Link onClick={() => {
        isLink ? navTo(-1) : setIsOpen(false)
    }}>
      <img className=" cursor-pointer" src={goBack} alt="goBack-icon"/>
    </Link>
    <h1 className="  text-[1.5rem] font-bold  md:text-[2rem] ">
     {Titel}
    </h1>
  </span>
    )
}

export default GoBack