import React, {useEffect, useState} from "react";
import AddImages from "../../BecomeHost/AddImages";
import Modal from "../../ui/Modal";
import GoBack from "../../ui/GoBack";
import Topbar from "../../ui/Topbar";
import imG from "../../../assest/img/Bizerte.jpg";
import axios from "axios";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function DisplayImages({Images, setImages, id, Slog}) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();
    const SaveTheImages = async () => {
        const userToken = Cookies.get("Token");
        const ValidImages = Images.filter((image) => image.img !== null);
        const formattedImages = ValidImages.map((image, index) => {
            return {
                UrlImage: image.img,
                Couverture: index === 0, // You may adjust this based on your requirements
            };
        });
        const IsGraterthen5 = Images?.slice(0, 5).every(
            (image) => image.img != null
        );
        if (!IsGraterthen5 && Images.length > 5) {
            toast.error("le minimum d'images est de 5");
        } else {
            try {
                const response = await axios.post(
                    `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateImage`,
                    {
                        Slog: Slog,

                        Images: formattedImages,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                toast.success(t("Msgs.Updatesuccessful", {text: t("Labels.Photos")}));
                setIsOpen(false);
                return response?.data;
            } catch (error) {
                throw new Error(`Error fetching geocoding data: ${error.message}`);
            }
        }
    };

    return (
        <div className="  h-full w-full  flex flex-col gap-[1.88rem] ">
            <div className=" flex font-semibold  items-center justify-between">
                <h1 className=" text-xl"> {t("Labels.Photos")} </h1>
                <span
                    onClick={() => setIsOpen(true)}
                    className=" cursor-pointer text-sec"
                >
          {t("Actions.Edit")}
        </span>
            </div>

            <div className=" flex h-full  items-center    lg:h-[25rem]   w-full flex-col md:flex-row gap-5 ">
                <div className="    images w-full  h-[15rem] lg:h-[25rem]    ">
                    <img
                        className="rounded-md w-full h-full object-cover"
                        src={Images[0]?.img}
                        alt={`SuperHost Tunisia - ${Slog}`}
                    />
                </div>

                <div className="    grid gap-3 w-full  h-full  grid-cols-2  md:grid-cols-2">
                    <img
                        className=" lg:flex hidden rounded-md w-full  lg:h-[12rem]   object-cover"
                        src={Images[1]?.img}
                        alt={`SuperHost Tunisia - ${Slog}`}
                    />
                    <img
                        className=" lg:flex hidden rounded-md w-full h-[12rem]  object-cover"
                        src={Images[2]?.img}
                        alt={`SuperHost Tunisia - ${Slog}`}
                    />{" "}
                    <img
                        className="  rounded-md   w-full h-[12rem]  object-cover"
                        src={Images[3]?.img}
                        alt={`SuperHost Tunisia - ${Slog}`}
                    />
                    <img
                        className="  rounded-md   w-full h-[12rem]  object-cover"
                        src={Images[4]?.img}
                        alt={`SuperHost Tunisia - ${Slog}`}
                    />
                </div>
            </div>

            {/* edit pic */}
            <Modal Style={" w-full h-full "} isFullPage={true} isOpen={isOpen}>
        <span className=" z-50  bg-white  w-full absolute top-0 right-0 lg:hidden bolck  ">
          <span></span>
          <Topbar
              onClick={() => setIsOpen(false)}
              // goBack={false}:
              text={"Brise Gammarth"}
          />
        </span>
                <span className=" z-40  w-full  h-full px-[1rem]  lg:px-[8%] overflow-auto   pt-20 ">
          <div className=" hidden  lg:flex items-center justify-between">
            <GoBack isLink={false} setIsOpen={setIsOpen} Titel={"Photos"}/>
            <div className=" flex gap-2 ">
              <button
                  onClick={() => setIsOpen(false)}
                  className=" px-[2rem] h-[3rem]  rounded-md border-2 border-sec text-sec "
              >
                {t("Actions.Cancel")}
              </button>
              <button
                  onClick={SaveTheImages}
                  className=" px-[2rem] h-[3rem]  rounded-md bg-main text-white "
              >
                {t("Actions.Save")}
              </button>
            </div>
          </div>

          <p className=" pt-5 text-gray-400 text-sm">
            {t("Host.AddImages.AddMinimumImages")}
          </p>
          <AddImages Images={Images} setImages={setImages} editpage={true}/>
          <div
              className=" absolute bottom-0 right-0 w-full items-center justify-between px-5 pb-8 pt-2 border-t-2 bg-white  lg:hidden flex gap-2 ">
            <button
                onClick={() => setIsOpen(false)}
                className=" px-[2rem] h-[3rem]  rounded-md border-2 border-sec text-sec "
            >
              {t("Actions.Cancel")}
            </button>
            <button
                onClick={SaveTheImages}
                className=" px-[2rem] h-[3rem]  rounded-md bg-main text-white "
            >
              {t("Actions.Save")}
            </button>
          </div>
        </span>
            </Modal>
        </div>
    );
}

export default DisplayImages;
