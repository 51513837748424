import React, {useContext, useState} from "react";
import {Usercontext} from "../../context/Usercontexte";
import {useClickOutside} from "../../hooks/UseClickOutSide";
import {useRef} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";

function Lang() {
    const {currency, setCurrency} = useContext(Usercontext);
    const [isOpen, setIsOpen] = useState(false);
    const OpenRef = useRef();
    const {t, i18n} = useTranslation();

    useClickOutside(OpenRef, () => setIsOpen(false));

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };


    const Langs = [
        {
            text: "English",
            value: "en",
        },
        {
            text: "Español",
            value: "es",
        },
        {
            text: "Français",
            value: "fr",
        },
    ];

    return (
        <div
            ref={OpenRef}
            onClick={() => setIsOpen(!isOpen)}
            className="     text-sm cursor-pointer  flex  pb-1   rounded-md relative "
        >
      <span className="  w-full flex  items-center gap-1 ">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
          <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
          />
        </svg>
      </span>
            {isOpen && (
                <span
                    className=" bg-white top-8 right-[50%] transform translate-x-[50%]   w-[5rem]  items-center justify-center shadow-md rounded-md absolute flex flex-col  ">
          {Langs.map((cur) => (
              <span
                  onClick={() => changeLanguage(cur.value)}
                  className={`py-1  w-full bg-white  flex items-center hover:bg-gray-100 justify-center ${
                      i18n.language == cur.value ? " text-sec bg-gray-50" : ""
                  } `}
              >
              {cur.text}
            </span>
          ))}
        </span>
            )}
        </div>
    );
}

export default Lang;
