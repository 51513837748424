import React, {useState, useEffect} from "react";
import {TravelType} from "../../../assest/img/Host/HostData";
import {proprse} from "../../../assest/ImgProposeData";
import CloseIcon from "@mui/icons-material/Close";
import GrayLine from "../../ui/GrayLine";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import checkBtn from "../../../assest/img/checkBtn.jpg"


function UpdateTypeOfTravel({travelType, setTravelType, Slog, props}) {
    const [SeeContent, setSeeContent] = useState([]);

    const isMobile = useMediaQuery({maxWidth: 767});
    const [EditType, setEditType] = useState(false);
    const [NewTravelType, setNewTravelType] = useState({
        TypeDeVoyageTravail: 0,
        TypeDeVoyageDetente: 0,
        TypeDeVoyageSAmuser: 0,
        TypeDeVoyageDecouvrir: 0,
    });

    const {t} = useTranslation();

    const {id} = useParams();

    useEffect(() => {
        setNewTravelType(travelType);
    }, [travelType, EditType]);

    const handleToggle = (type) => {
        setNewTravelType((prevState) => ({
            ...prevState,
            [type]: prevState[type] === 0 ? 1 : 0,
        }));
    };

    async function SaveTypeVoyage() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        axios
            .post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateTypeDeVoyage",
                {
                    Slog: Slog,
                    ID_Proprietaire: UserId,
                    TypeDeVoyageTravail: NewTravelType.TypeDeVoyageTravail,
                    TypeDeVoyageDetente: NewTravelType.TypeDeVoyageDetente,
                    TypeDeVoyageSAmuser: NewTravelType.TypeDeVoyageSAmuser,
                    TypeDeVoyageDecouvrir: NewTravelType.TypeDeVoyageDecouvrir,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                setTravelType(NewTravelType);
                setEditType(false);
                toast.success(
                    t("Msgs.Updatesuccessful", {text: t("Labels.TravelType")})
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const Type = [
        {
            id: 1,
            title: t("Home.TravelType.BusinessTrip"),
            img: TravelType.travaille,
            value: "TypeDeVoyageTravail",
            contient: [
                {
                    text: t("Host.Proposer.EspaceDeTravail"),
                    img: proprse.workspacePink,
                    value: "EspaceDeTravail",
                },

                {
                    text: t("Host.Proposer.fibreoptique"),
                    img: proprse.fiberPink,
                    value: "fibreoptique",
                },
                {
                    text: t("Host.Proposer.Paisible"),
                    img: proprse.PeacefulPink,
                    value: "Paisible",
                },
                {
                    text: t("Host.Proposer.Restaurantsetcafes"),
                    img: proprse.coffeePink,
                    value: "Restaurantsetcafes",
                },
                {
                    text: t("Host.Proposer.quartierCentral"),
                    img: proprse.centerQPink,
                    value: "quartierCentral",
                },
                {
                    text: t("Host.Proposer.quartiercommercant"),
                    img: proprse.downtownPink,
                    value: "quartiercommercant",
                },
            ],
            // p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mollis aliquam arcu, vel iaculis mi pretium et.",
        },
        {
            id: 2,
            title: t("Home.TravelType.TripToRelax"),
            img: TravelType.detendre,
            value: "TypeDeVoyageDetente",
            contient: [
                {
                    text: t("Host.Proposer.Piscine"),
                    img: proprse.poolPink,
                    value: "Piscine",
                },
                {
                    text: t("Host.Proposer.auborddelamer"),
                    img: proprse.NearToSeaPink,
                    value: "auborddelamer",
                },
                {
                    text: "jacuzzi",
                    img: proprse.jacuzziPink,
                    value: "Jacuzzi",
                },
                {
                    text: t("Host.Proposer.baignoire"),
                    img: proprse.BaignoirePink,
                    value: "baignoire",
                },
                {
                    text: t("Host.Proposer.livres"),
                    img: proprse.booksPink,
                    value: "livres",
                },
                {
                    text: t("Host.Proposer.Cheminee"),
                    img: proprse.ChemineePink,
                    value: "Cheminee",
                },
                {
                    text: t("Host.Proposer.Paisible"),
                    img: proprse.PeacefulPink,
                    value: "Paisible",
                },
                {
                    text: t("Host.Proposer.Petitdejeunerdisponible"),
                    img: proprse.breakfastPink,
                    value: "Petitdejeunerdisponible",
                },
                {
                    text: t("Host.Proposer.quartierCalme"),
                    img: proprse.calmePink,
                    value: "quartierCalme",
                },
                {
                    text: t("Host.Proposer.vuemer"),
                    img: proprse.seaPink,
                    value: "vuemer",
                },
                {
                    text: t("Host.Proposer.espacerepasexterieur"),
                    img: proprse.spaceOutDoorPink,
                    value: "espacerepasexterieur",
                },
                {
                    text: t("Host.Proposer.jardin"),
                    img: proprse.gardenPink,
                    value: "jardin",
                },
                {
                    text: t("Host.Proposer.quartiercommercant"),
                    img: proprse.downtownPink,
                    value: "quartiercommercant",
                },
            ],
            // p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mollis aliquam arcu, vel iaculis mi pretium et.",
        },
        {
            id: 3,
            title: t("Home.TravelType.TripToHaveFun"),
            img: TravelType.LMamuser,
            value: "TypeDeVoyageSAmuser",
            contient: [
                {
                    text: t("Host.Proposer.Piscine"),
                    img: proprse.poolPink,
                    value: "Piscine",
                },

                {
                    text: "jacuzzi",
                    img: proprse.jacuzziPink,
                    value: "Jacuzzi",
                },
                {
                    group: "détente",
                    text: t("Host.Proposer.vuemer"),
                    img: proprse.seaPink,
                    value: "vuemer",
                },
                {
                    text: t("Host.Proposer.Patio"),
                    img: proprse.patioPink,
                    value: "Patio",
                },
                {
                    text: t("Host.Proposer.rooftop"),
                    img: proprse.roofPink,
                    value: "rooftop",
                },
                {
                    text: t("Host.Proposer.jeux"),
                    img: proprse.gamePink,
                    value: "jeux",
                },
                {
                    text: t("Host.Proposer.espacerepasexterieur"),
                    img: proprse.spaceOutDoorPink,
                    value: "espacerepasexterieur",
                },
                {
                    text: t("Host.Proposer.jardin"),
                    img: proprse.gardenPink,
                    value: "jardin",
                },
                {
                    text: t("Host.Proposer.quartiercommercant"),
                    img: proprse.downtownPink,
                    value: "quartiercommercant",
                },
                {
                    text: t("Host.Proposer.Restaurantsetcafes"),
                    img: proprse.coffeePink,
                    value: "Restaurantsetcafes",
                },
                {
                    text: t("Host.Proposer.Spacieux"),
                    img: proprse.SpacieuxPink,
                    value: "Spacieux",
                },
                {
                    text: t("Host.Proposer.quartierCentral"),
                    img: proprse.centerQPink,
                    value: "quartierCentral",
                },
            ],
            // p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mollis aliquam arcu, vel iaculis mi pretium et.",
        },
        {
            id: 4,
            title: t("Home.TravelType.TripToDiscover"),
            img: TravelType.decouvrir,
            contient: [
                {
                    text: t("Host.Proposer.Transportsencommun"),
                    img: proprse.transportPink,
                    value: "Transportsencommun",
                },
                {
                    text: t("Host.Proposer.Petitdejeunerdisponible"),
                    img: proprse.breakfastPink,
                    value: "Petitdejeunerdisponible",
                },
                {
                    text: t("Host.Proposer.quartierCentral"),
                    img: proprse.centerQPink,
                    value: "quartierCentral",
                },
                {
                    text: t("Host.Proposer.Restaurantsetcafes"),
                    img: proprse.coffeePink,
                    value: "Restaurantsetcafes",
                },
                {
                    text: t("Host.Proposer.quartierCentral"),
                    img: proprse.centerQPink,
                    value: "quartierCentral",
                },
            ],
            value: "TypeDeVoyageDecouvrir",
            // p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mollis aliquam arcu, vel iaculis mi pretium et.",
        },
    ];

    return (
        <div
            className={`flex flex-col gap-5  ${
                EditType ? " w-full" : "  w-full lg:w-[50%]"
            } `}
        >
            <div className=" flex flex-col gap-2 px-3 ">
                <div className="inputs flex flex-col  w-full gap-3">
                    <h1 className=" font-semibold text-lg"> {t("Labels.TravelType")} </h1>
                    {!EditType ? (
                        <div
                            className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
              <span className="text-left">
                <ul>
                  {travelType?.TypeDeVoyageTravail === 1 && (
                      <li key="TypeDeVoyageTravail">
                          • {t("Home.TravelType.BusinessTrip")}
                      </li>
                  )}

                    {travelType?.TypeDeVoyageDetente === 1 && (
                        <li key="TypeDeVoyageDetente">
                            • {t("Home.TravelType.TripToRelax")}
                        </li>
                    )}

                    {travelType?.TypeDeVoyageSAmuser === 1 && (
                        <li key="TypeDeVoyageSAmuser">
                            • {t("Home.TravelType.TripToHaveFun")}
                        </li>
                    )}

                    {travelType?.TypeDeVoyageDecouvrir === 1 && (
                        <li key="TypeDeVoyageDecouvrir">
                            • {t("Home.TravelType.TripToDiscover")}
                        </li>
                    )}
                </ul>
              </span>
                            <button
                                className="font-semibold  cursor-pointer text-sec"
                                onClick={() => setEditType(true)}
                            >
                                {t("Actions.Edit")}
                            </button>
                        </div>
                    ) : (
                        <div className=" bg-white shadow-lg  border-[1px]  rounded-md flex flex-col gap-3 p-3">
                            <div className="props grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4  pb-10 ">
                                {Type.map((item, index) => (
                                    <>
                    <span
                        key={index}
                        onClick={() => {
                            !isMobile && handleToggle(item.value);

                            setSeeContent(item.value);
                        }}
                        className={`  ${
                            NewTravelType[item?.value] === 1
                                ? " border-sec text-sec "
                                : " text-white"
                        } bg-white group overflow-hidden travelType w-full text-xl font-bold  flex flex-col     relative           h-full md:h-[24rem]  cursor-pointer    border-solid border-2 rounded-lg `}
                    >
                      <span className=" h-full flex flex-col z-10 gap-2">
                        <img
                            src={item.img}
                            className={` w-full object-cover  lg:h-[50vh]  h-[35vh]  `}
                            alt="SuperHost Tunisia"

                        />
                        <h2 className=" absolute bottom-3 left-3  z-10  text-3xl ">
                          {item.title}
                        </h2>
                        <span className=" bg-black opacity-25 h-full  absolute top-0 right-0 w-full  "></span>
                      </span>


                        {/* More Info  */}
                        <div
                            className={`p-2   overflow-auto h-full ${
                                item?.value == travelType ? " border-sec  " : " "
                            }   absolute  bg-white h-full border-solid   text-main  border-2   py-10   duration-150  right-0  flex top-[100%]  md:group-hover:top-0  w-full z-50  flex-col gap-3`}
                        >
                        <h1 className=" font-semibold"> {item?.title}</h1>
                            {item.contient.map((i, index) => (
                                <div className=" flex justify-between  w-full">
                            <span
                                key={index}
                                className=" text-base font-normal w-full   flex-col flex z-40   items-center gap-3 "
                            >
                              <span className=" flex font-semibold  w-full items-start gap-3">
                                <img
                                    className=" w-[1.5rem] "
                                    src={i.img}
                                    alt="SuperHost Tunisia"

                                />
                                  {i.text}
                              </span>
                              <GrayLine/>
                            </span>
                                    {props[i?.value] == 1 && (
                                        <img
                                            src={checkBtn}
                                            className=" w-[2rem] h-[2rem] "
                                            alt="SuperHost Tunisia"

                                        />
                                    )}
                                </div>
                            ))}
                      </div>
                    </span>
                                        {/* Mobile  */}
                                        <div
                                            className={`p-2 z    flex  gap-5   ${
                                                SeeContent == item.value ? "top-0" : " -top-[-100%]  "
                                            }   duration-150   md:hidden  fixed bg-white w-full h-full        right-0  z-[5100]  flex-col gap-3`}
                                        >
                                            <div
                                                className="  border-b-2 w-full h-[3rem] flex items-center justify-center  ">
                        <span
                            onClick={() => setSeeContent(null)}
                            className=" absolute top-4 left-3"
                        >
                          {" "}
                            <CloseIcon/>{" "}
                        </span>
                                                <h3 className="  font-semibold"> {item?.title}</h3>
                                            </div>

                                            {item.contient.map((i, index) => (
                                                <div className=" flex justify-between w-full   ">
                          <span
                              key={index}
                              className=" text-base font-normal w-full   flex-col flex z-40   items-center gap-3 "
                          >
                            <span className=" flex font-semibold  w-full items-start gap-3">
                              <img
                                  className=" w-[1.5rem] "
                                  src={i.img}
                                  alt="SuperHost Tunisia"

                              />
                                {i.text}
                            </span>
                            <GrayLine/>
                          </span>
                                                    {props[i?.value] == 1 && (
                                                        <img
                                                            src={checkBtn}
                                                            className=" w-[2.5rem] h-[2.5rem] "
                                                            alt="SuperHost Tunisia"

                                                        />
                                                    )}
                                                </div>
                                            ))}
                                            <div
                                                className=" px-10 w-full absolute gap-3 z-50  bottom-0  right-0 h-[5rem] flex  items-center justify-center border-t-2 ">
                                                <button
                                                    onClick={() => setSeeContent(null)}
                                                    className="   rounded-lg font-semibold border-[1px] text-gray-400 p-4  w-full"
                                                >
                                                    {t("Actions.Cancel")}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        handleToggle(SeeContent);
                                                        setSeeContent(null);
                                                    }}
                                                    className={` ${
                                                        NewTravelType[item?.value] === 1
                                                            ? " bg-red-600"
                                                            : "bg-main"
                                                    }  rounded-lg font-semibold text-white p-4 w-full`}
                                                >
                                                    {NewTravelType[item?.value] === 1
                                                        ? t("Actions.Delete")
                                                        : t("Actions.Select")}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>

                            <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                                <button
                                    onClick={() => setEditType(false)}
                                    className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                                >
                                    {t("Actions.Cancel")}
                                </button>
                                <button
                                    onClick={SaveTypeVoyage}
                                    className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                                >
                                    {t("Actions.Save")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateTypeOfTravel;
