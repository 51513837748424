// Importing necessary modules and dependencies
import axios from "axios";
import {createContext, useState, useEffect, useContext} from "react";
import Cookies from "js-cookie";

export const Usercontext = createContext();

// Userprovider Component
const Userprovider = ({children}) => {
    const [state, setState] = useState("from context");
    const [User, setUser] = useState(null);
    const [Favotite, setFavotite] = useState([]);
    const [FavotitePartenaier, setFavotitePartenaier] = useState([]);
    const [Msgs, setMsgs] = useState([]);

    const [hasProperty, setHasProperty] = useState(false);

    const storedCurrency = JSON.parse(localStorage.getItem("currency"));
    const [currency, setCurrency] = useState(
        storedCurrency || {code: "TND", symbol: "TND"}
    );

    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        const getExchangeRate = async () => {
            try {
                const response = await axios.get(
                    `https://visiontunisie.com/api/v1/tnd-value/${currency?.code}`
                );
                setExchangeRate(response.data.value);
            } catch (error) {
                console.error("Error fetching exchange rate:", error);
            }
        };
        if (currency) {
            getExchangeRate();
        }
    }, [currency]);

    useEffect(() => {
        // Update currency in localStorage when it changes
        localStorage.setItem("currency", JSON.stringify(currency));
    }, [currency]);

    useEffect(() => {
        const userToken = Cookies.get("Token");
        if (!userToken) return;
        const UserId = localStorage.getItem("ID_Utulisateur");
        const GetPlaceById = async () => {
            try {
                const response = await axios.post(
                    `https://visiontunisie.com/api/v1/Conversation/MessageNotSeen`,
                    {
                        ID_Utulisateur: UserId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                setMsgs(response?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        GetPlaceById();
    }, [User]);

    useEffect(() => {
        const userToken = Cookies.get("Token");
        if (!User && userToken) {
            const UserId = localStorage.getItem("ID_Utulisateur");
            if (!userToken || !UserId) return;

            axios
                .post(
                    "https://visiontunisie.com/api/v1/GestionDesUtulisateur/profile",
                    {
                        ID_Utulisateur: UserId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                )
                .then((response) => {
                    setUser(response.data.userProfile);
                    setHasProperty(response.data.hasProperties);
                })
                .catch((err) => {
                    // Handle the error without throwing it
                    console.error("Error fetching user profile:", err);
                });
        }
    }, [User]); // You might also want to include User in the dependency array if you're using it within the effect

    useEffect(() => {
        const userToken = Cookies.get("Token");

        if (User && userToken) return;
        const UserId = localStorage.getItem("ID_Utulisateur");
        if (!userToken) return;
        axios
            .post(
                "https://visiontunisie.com/api/v1/AdminPartenaire/selectFavouriteByIDUser",
                {
                    ID_Utulisateur: UserId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => setFavotite(response.data.ID_Bien))
            .catch((err) => {
                console.error("Error occurred while fetching data:", err);
            });
    }, [User]); // Ensure these dependencies are correct

    useEffect(() => {
        const userToken = Cookies.get("Token");
        if (User && userToken) return;

        const UserId = localStorage.getItem("ID_Utulisateur");
        if (!userToken) return;
        axios
            .post(
                "https://visiontunisie.com/api/v1/GestionPartenaire/selectFavouriteByIDUser",
                {
                    ID_Utulisateur: UserId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => setFavotitePartenaier(response.data.ID_Prestataire))
            .catch((err) => {
                console.error("Error occurred while fetching data:", err);
            });
    }, []);

    return (
        <Usercontext.Provider
            value={{
                setState,
                setUser,
                Msgs,
                setMsgs,
                User,
                state,
                Favotite,
                currency,
                hasProperty,
                setCurrency,
                setFavotite,
                FavotitePartenaier,
                setFavotitePartenaier,
                exchangeRate,
                setHasProperty,
            }}
        >
            {children}
        </Usercontext.Provider>
    );
};

// Exporting the Userprovider component
export default Userprovider;
