import React from "react";

const SuperHostLogo = ({className, color, fill, height, width, stroke}) => (
    <span className={`  ${className}`}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "396"}
        height={height ? height : "396"}
        viewBox="0 0 335 460"
        fill={fill ? color : "none"}
    >
      <path
          d="M323.589 11.2577C316.657 4.4205 308.464 1.00375 299.024 1.00375H298.158C288.706 1.00375 280.518 4.42425 273.589 11.2577C266.648 18.2902 263.178 26.6969 263.178 36.4628V151.705C235.372 131.373 203.492 121.153 167.498 121.153C131.504 121.153 99.6241 131.369 71.822 151.701V36.4628C71.822 26.6969 68.3518 18.294 61.4115 11.2577C54.4822 4.4205 46.2864 1 36.8462 1H35.9758C26.5319 1 18.3435 4.4205 11.4105 11.254C4.47018 18.2902 1 26.6969 1 36.4628V423.295C1 433.259 4.47018 441.565 11.4105 448.207C18.3435 455.239 26.5356 458.757 35.9758 458.757H36.8462C46.2827 458.757 54.4785 455.239 61.4115 448.207C67.2297 442.642 70.5739 435.888 71.5146 427.984C71.6961 426.46 71.822 424.905 71.822 423.295V290.834C71.822 263.875 81.0733 240.923 99.576 221.969C118.26 203.02 140.907 193.543 167.498 193.543C194.089 193.543 216.444 203.02 235.139 221.969C253.834 240.923 263.178 263.871 263.178 290.834V423.295C263.178 424.905 263.3 426.46 263.485 427.984C264.426 435.888 267.77 442.642 273.589 448.207C280.521 455.239 288.706 458.757 298.158 458.757H299.024C308.468 458.757 316.657 455.239 323.589 448.207C330.53 441.565 334 433.259 334 423.295V36.4628C334 26.6969 330.53 18.294 323.589 11.2577Z"
          stroke={color}
          strokeWidth={stroke || "1"}
          strokeMiterlimit="10"
      />
      <path
          d="M176.536 258.202C175.156 254.044 171.387 251 166.799 251C162.212 251 158.446 254.044 157.066 258.202C142.634 262.48 132 275.807 132 291.731C132 311.089 147.616 326.833 166.799 326.833C185.983 326.833 201.602 311.089 201.602 291.731C201.602 275.807 190.965 262.484 176.536 258.202ZM166.799 317.132C152.918 317.132 141.622 305.734 141.622 291.731C141.622 280.592 148.814 271.214 158.722 267.795C160.623 270.268 163.462 271.961 166.799 271.961C170.137 271.961 172.979 270.264 174.881 267.795C184.793 271.21 191.981 280.592 191.981 291.731C191.981 305.734 180.685 317.132 166.799 317.132Z"
          stroke={color}
          strokeWidth={stroke || "1"}
          strokeMiterlimit="10"
      />
    </svg>
  </span>
);

export default SuperHostLogo;
