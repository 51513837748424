import {
    SortableContext,
    verticalListSortingStrategy,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import AddIcon from "@mui/icons-material/Add";
import {DraggableImg} from "./draggable ";
import {useEffect, useState} from "react";
import gsap from "gsap";
import {UploadImg} from "../../API/UploadImg";

export const Droppable = ({Images, setImages, editpage}) => {

    const [IsLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (editpage) return
        gsap.fromTo(
            ".images",
            {
                duration: 0.1,
                y: 100,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                stagger: 0.06,
                ease: "power3.out",
            }
        );
    }, []);

    if (Images?.length === 0) {
        return null;
    }


    const LastIndex = Images?.length - 1;

    const addMoreImages = async (e) => {
        setIsLoading(true);

        try {
            const files = e.target.files;

            if (files?.length > 0) {
                const newTasks = [...Images];

                for (let i = 0; i < files?.length; i++) {
                    const newId = Images?.length + i + 1;
                    const imageUrl = await UploadImg(files[i]);
                    newTasks.push({id: newId, img: imageUrl});
                }

                setImages(newTasks);
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error uploading images:", error);
            setIsLoading(false);
        }
    };
    // when the images are validated
    const ValidImages = Images?.slice(0, 5).every((image) => image.img != null);

    const [firstTask, ...remainingTasks] = Images;


    return (
        <div className="flex overflow-hidden flex-col md:flex-row gap-4 pb-6">
            <SortableContext items={Images} strategy={horizontalListSortingStrategy}>
                <div className=" flex flex-col gap-5 w-full ">
                    <div className=" flex  w-full flex-col md:flex-row gap-5 ">
                        <div className=" images w-full  h-full">
                            <DraggableImg
                                key={firstTask?.id}
                                id={firstTask?.id}
                                img={firstTask?.img}
                                index={0}
                                setImages={setImages}
                            />
                        </div>

                        <div className="grid gap-3 w-full  grid-cols-1  md:grid-cols-2">
                            {remainingTasks?.slice(0, 4).map((task, index) => (
                                <DraggableImg
                                    key={task?.id}
                                    LastIndex={LastIndex}
                                    id={task?.id}
                                    img={task?.img}
                                    index={index + 1}
                                    setImages={setImages}
                                />
                            ))}
                        </div>
                    </div>

                    {/* the rest images */}
                    <div className="  grid  grid-cols-1 md:grid-cols-4 gap-4">
                        {remainingTasks.slice(4).map((task, index) => (
                            <DraggableImg
                                key={task?.id}
                                id={task?.id}
                                img={task?.img}
                                LastIndex={LastIndex}
                                index={index + 5} // Adjusted index to start from 5
                                setImages={setImages}
                            />
                        ))}
                        {ValidImages && (
                            <label
                                htmlFor="AddMoreImg"
                                className="  h-[10rem] flex items-center justify-center  md:h-[13rem] border-solid border-[1px] w-full "
                            >
                                {
                                    IsLoading ? 'loading..' : <AddIcon className=" scale-[2.5] "/>
                                }

                                <input
                                    onChange={addMoreImages}
                                    className=" hidden"
                                    type="file"
                                    name="AddMoreImg"
                                    multiple={true}
                                    id="AddMoreImg"
                                />
                            </label>
                        )}
                    </div>
                </div>
            </SortableContext>
        </div>
    );
};
