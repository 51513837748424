import React, {useContext, useEffect, useState} from "react";
import logo from "../../src/assest/img/logo.svg";
import Button from "./ui/Button";
import {Link as ScrollLink} from "react-scroll";
import Menu from "../assest/img/Menu.svg";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import "../App.css";
import {UseToggle} from "../hooks/UseToggle";
import SideBar from "./SideBar";
import {useTranslation} from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";
import Modal from "./ui/Modal";
import {Usercontext} from "../context/Usercontexte";
import UserDropDown from "./Navbar/UserDropDown";
import {toast} from "react-toastify";
import "./Navbar.css";
import Cookies from "js-cookie";
import Currency from "./Navbar/Currency.jsx";
import Lang from "./Navbar/Lang.jsx";
import {SocketContext} from "../context/SocketContext.js";

function Navbar({NotFixed, dashboard}) {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [hasScrolled, setHasScrolled] = useState(false);
    const {hasProperty, User, setUser, Msgs, setHasProperty} =
        useContext(Usercontext);
    const {messages} = useContext(SocketContext);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setHasScrolled(prevScrollPos < currentScrollPos && currentScrollPos > 0);
        setPrevScrollPos(currentScrollPos);
    };

    const NavTo = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    const {ToggelStatus, Status} = UseToggle();

    const location = useLocation();

    // Check if the current route path contains "/dashboard"
    const isDashboard = location.pathname.includes("/dashboard");

    const GetIsActive = (path) => location.pathname === path;

    const Links = dashboard
        ? [
            {
                text: t("HostMode.Calendar"),
                link: "/dashboard/calendrier",
                nav: true,
                isActive: GetIsActive(`/dashboard/calendrier/${id}`),
            },
            {
                text: t("HostMode.Accommodations"),
                link: "/dashboard",
                nav: true,
                isActive: GetIsActive("/dashboard"),
            },
            {
                text: t("HostMode.Reservations"),
                nav: true,
                link: "/dashboard/reservations",
                isActive: GetIsActive("/dashboard/reservations"),
            },
            {
                text: t("HostMode.Revenues"),
                link: "/dashboard/revenues",
                nav: true,
                isActive: GetIsActive("/dashboard/revenues"),
            },
            {
                text: t("HostMode.Messages"),
                link: "/dashboard/messages",
                nav: true,
                notification:
                    Msgs[Msgs?.length - 1]?.Allunseen_message || messages?.length,
                isActive: GetIsActive(`/dashboard/messages/${id}`),
            },
        ]
        : [
            {
                text: t("NavBar.Accommodation"),
                link: "/search-by-map",
                nav: true,
                isActive: GetIsActive("/search-by-map"),
            },

            {
                text: t("NavBar.Blog"),
                link: "/blogs",
                nav: true,
                isActive: GetIsActive("/blogs"),
            },
        ];

    function LogOut() {
        localStorage.removeItem("ID_Utulisateur");
        setUser(null);
        setHasProperty(false);
        Cookies.remove("Token");
        NavTo("/");
    }

    return (
        <>
            <header
                className={`   ${
                    hasScrolled && !NotFixed ? "transform -translate-y-full" : ""
                } ${
                    NotFixed ? "  relative" : "fixed"
                }   top-0 right-0 duration-200 bg-white w-full items-center z-[60] h-[5rem] lg:h-[6rem] flex  px-[1rem] justify-between   lg:px-[6%] flex-row`}
            >
                {/* NavMenu */}
                <span
                    className=" z-[100]    lg:hidden flex"
                    onClick={() => ToggelStatus(!Status)}
                    aria-label="Toggle Menu"
                >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </span>

                <span className=" flex flex-row gap-4 font-semibold">
          <Link to="/">
            <img
                loading="auto"
                title="SuperHost Logo"
                width={200}
                height={200}
                src={logo}
                className=" w-[8rem] h-[3rem]  mx-[2.4rem] "
                alt="SuperHost Tunisia"
            />
          </Link>
          <span className=" items-center  flex-row gap-4 font-semibold hidden lg:flex">
            {Links.map((i, index) => (
                <div key={index}>
                    {i.nav ? (
                        <Link
                            rel="canonical"
                            className={`  relative ${
                                i.isActive ? " text-sec" : ""
                            } hover:text-sec`}
                            to={i.link}
                        >
                            {i.text}
                            {i?.notification > 0 && (
                                <span
                                    className=" text-white bg-sec flex items-center justify-center text-sm h-[1.1rem] w-[1.1rem] -top-3 -right-3 absolute rounded-[50%] ">
                        {i?.notification}
                      </span>
                            )}
                        </Link>
                    ) : (
                        <ScrollLink
                            smooth={true}
                            duration={500}
                            to={`${i.link}`}
                            key={index}
                            className=" cursor-pointer hover:text-sec"
                        >
                            {i.text}{" "}
                        </ScrollLink>
                    )}
                </div>
            ))}

              {/* Lang */}
              <Lang/>
              {/* currency */}
              {!isDashboard && <Currency/>}
          </span>
        </span>
                <span className=" flex  items-center gap-5">
          {User &&
              (!isDashboard ? (
                  <Link className="hidden lg:flex" to="/dashboard">
                      {t("NavBar.HostMode")}
                  </Link>
              ) : (
                  <Link className="hidden lg:flex" to="/search-by-map">
                      {t("Labels.TravelerMode")}
                  </Link>
              ))}
                    {User ? (
                        <UserDropDown
                            hasProperty={hasProperty}
                            Msgs={Msgs}
                            dashboard={dashboard}
                            LogOut={LogOut}
                            setHasProperty={setHasProperty}
                            UserName={User?.Nom}
                            UserImg={User?.ImageProfile}
                        />
                    ) : (
                        <span className=" flex flex-row  items-center ">
              <span className="  flex">
                <Link
                    state={{from: location}}
                    to={"/auth"}
                    className="   rounded-md lg:border-0 border-[1px] text-main px-3 border-main py-1 font-medium  "
                >
                  {t("NavBar.Login")}
                </Link>
              </span>



              <Link
                  to={User ? "/host" : "/auth/sign-up"}
                  className=" group hidden md:flex  text-sec lg:flex ml-4 hover:text-white  items-center justify-center hover:bg-sec rounded-lg   border-solid border-2 border-sec relative  h-[3rem] px-3 "
              >
                <h1 className=" font-semibold ">
                  {" "}
                    {hasProperty
                        ? t("NavBar.MenuBar.createNewListing")
                        : t("NavBar.BecomeHost")}
                </h1>
              </Link>

              
            </span>
                    )}
        </span>
            </header>
            {/* mobile View */}
            <SideBar
                dashboard={dashboard}
                Msgs={Msgs}
                isOpen={Status}
                isDashboard={isDashboard}
                ToggelStatus={ToggelStatus}
                Links={Links}
                t={t}
            />
        </>
    );
}

export default Navbar;
