import vila from "../assest/img/Host/Villa.svg";
import VillaPink from "../assest/img/Host/VillaPink.svg";
import chambre from "../assest/img/Host/Chambre.svg";
import ChambrePink from "../assest/img/Host/ChambrePink.svg";
import Garage from "../assest/img/Propose/garage.svg";
import GaragePink from "../assest/img/Propose/GaragePink.svg";
import Baignoire from "../assest/img/Propose/Baignoire.svg";
import BaignoirePink from "../assest/img/Propose/BaignoirePink.svg";
import Douche from "../assest/img/Propose/Douche.svg";
import DouchePink from "../assest/img/Propose/DouchePink.svg";
import Eauchaude from "../assest/img/Propose/Eauchaude.svg";
import EauchaudePink from "../assest/img/Propose/EauchaudePink.svg";
import pool from "../assest/img/Propose/piscine.svg";
import poolPink from "../assest/img/Propose/PiscinePink.svg";
import SechePink from "../assest/img/Propose/SechePink.svg";
import Seche from "../assest/img/Propose/Seche.svg";
import garden from "../assest/img/Propose/garden.svg";
import gardenPink from "../assest/img/Propose/gardenPink.svg";
import EquipementPink from "../assest/img/Propose/EquipementPink.svg";
import Equipement from "../assest/img/Propose/Equipement.svg";
import lit from "../assest/img/lit.png";

import Barbecue from "./img/Propose/Barbecue.svg";
import aide from "./img/Propose/aide.svg";
import bienUnique from "./img/Propose/bien unique.svg";
import billard from "./img/Propose/billard.svg";
import CamerasDeSurveillance from "./img/Propose/CamerasDeSurveillance.svg";
import Cheminee from "./img/Propose/Cheminee.svg";
import climatiseur from "./img/Propose/climatiseur.svg";
import cuisine from "./img/Propose/cuisine.svg";
import detecteurCo2 from "./img/Propose/detecteur co2.svg";
import detecteurFeux from "./img/Propose/detecteur feux.svg";
import doucheExterieur from "./img/Propose/douche exterieur.svg";
import elegant from "./img/Propose/elegant.svg";
import Extinguisher from "./img/Propose/Extinguisher.svg";
import familyHouse from "./img/Propose/family house.svg";
import Fitness from "./img/Propose/fitness.svg";
import jacuzzi from "./img/Propose/jacuzzi.svg";
import lunchArea from "./img/Propose/lunch area.svg";
import MachineLavee from "./img/Propose/machine a lavee.svg";
import parkingPayant from "./img/Propose/parking payant.svg";
import patio from "./img/Propose/patio.svg";
import Peaceful from "./img/Propose/Peaceful.svg";
import piano from "./img/Propose/piano.svg";
import Spacieux from "./img/Propose/Spacieux.svg";
import tv from "./img/Propose/tv.svg";
import wifi from "./img/Propose/wifi.svg";
import workspace from "./img/Propose/workspace.svg";

import tvPink from "./img/Propose/tvPink.svg";
import aidePink from "./img/Propose/aide selectionner.svg";
import BarbecuePink from "./img/Propose/Barbecue selectionner.svg";
import bienUniquePink from "./img/Propose/bien unique selectionner.svg";
import billardPink from "./img/Propose/billard selectionner.svg";
import CamerasDeSurveillancePink from "./img/Propose/CamerasDeSurveillance selectionner.svg";
import ChemineePink from "./img/Propose/Cheminee selectionner.svg";
import climatiseurPink from "./img/Propose/climatiseur selectionner.svg";
import cuisinePink from "./img/Propose/cuisine selectionner.svg";
import detecteurCo2Pink from "./img/Propose/detecteur co2 selectionner.svg";
import detecteurFeuxPink from "./img/Propose/detecteur feux selectionner.svg";
import doucheExterieurPink from "./img/Propose/douche exterieur selectionner.svg";
import elegantPink from "./img/Propose/elegant selectionner.svg";
import ExtinguisherPink from "./img/Propose/Extinguisher selectionner.svg";
import familyHousePink from "./img/Propose/family house selectionner.svg";
import FitnessPink from "./img/Propose/fitness selectionner.svg";
import jacuzziPink from "./img/Propose/jacuzzi selectionner.svg";
import lunchAreaPink from "./img/Propose/lunch area selectionner.svg";
import MachineLaveePink from "./img/Propose/machine a lavee selectionner.svg";
import parkingPayantPink from "./img/Propose/parking payant selectionner.svg";
import patioPink from "./img/Propose/patio selectionner.svg";
import PeacefulPink from "./img/Propose/Peaceful selectionner.svg";
import pianoPink from "./img/Propose/piano selectionner.svg";
import SpacieuxPink from "./img/Propose/Spacieux selectionner.svg";
// import tvPink from './img/Propose/tv.svg'
import wifiPink from "./img/Propose/wifi selectionner.svg";
import workspacePink from "./img/Propose/workspace selectionner.svg";

import parkingPink from "./img/Propose/parking.svg";
import parking from "./img/Propose/parking sellectionnee.svg";

import Brasero from "./img/Propose/Brasero.svg";
import BraseroPink from "./img/Propose/BraseroPink.svg";

import AccesAuLac from "./img/Propose/AccesAuLac.png";
import AccesAuLacPink from "./img/Propose/AccesAuLacPink.png";

import aidKit from "./img/Propose/aidKit.png";
import aidKitPink from "./img/Propose/aidKitPink.png";

import Central from "./img/Propose/central.svg";
import CentralPink from "./img/Propose/central selectionner.svg";

import chauffage from "./img/Propose/chauffage.png";
import chauffagePink from "./img/Propose/chauffagePink.png";

import transport from "./img/Propose/transport.png";
import transportPink from "./img/Propose/transportPink.png";

import bedPink from "./img/Propose/bedPink.png";
import bed from "./img/Propose/bed.png";

import Monument from "./img/Propose/Monument.png";
import MonumentPink from "./img/Propose/MonumentPink.png";

import fiber from "./img/Propose/fiber.png";
import fiberPink from "./img/Propose/fiberPink.png";

import centerQ from "./img/Propose/centerQ.png";
import centerQPink from "./img/Propose/centerQPink.png";

import eauChaude from "./img/Propose/eauChaude.png";
import eauChaudePink from "./img/Propose/eauChaudePink.png";

import calme from "./img/Propose/calme.png";
import calmePink from "./img/Propose/calmePink.png";

import NearToSea from "./img/Propose/NearToSea.png";
import NearToSeaPink from "./img/Propose/NearToSeaPink.png";

import towels from "./img/Propose/towels.png";
import towelsPink from "./img/Propose/towelsPink.png";

import garde from "./img/Propose/garde.png";
import gardePink from "./img/Propose/gardePink.png";

import bath from "./img/Propose/bath.png";
import bathPink from "./img/Propose/bathPink.png";

import breakfast from "./img/Propose/breakfast.png";
import breakfastPink from "./img/Propose/breakfastPink.png";

import downtown from "./img/Propose/downtown.png";
import downtownPink from "./img/Propose/downtownPink.png";

import seaPink from "./img/Propose/seaPink.png";
import sea from "./img/Propose/sea.png";

import coffeePink from "./img/Propose/R&CPink.png";
import coffee from "./img/Propose/R&C.png";

import gamePink from "./img/Propose/gamePink.png";
import game from "./img/Propose/game.png";

import spaceOutDoorPink from "./img/Propose/spaceOutDoorPink.png";
import spaceOutDoor from "./img/Propose/spaceOutDoor.png";

import booksPink from "./img/Propose/booksPink.png";
import books from "./img/Propose/books.png";

import roofPink from "./img/Propose/roofPink.png";
import roof from "./img/Propose/roof.png";

import dishwasherPink from "./img/Propose/dishwasherPink.png";
import dishwasher from "./img/Propose/dishwasher.png";

export const imageMappings = {
    AccesAuLac: AccesAuLacPink,
    AdapteAuxFamilles: familyHousePink,
    AppareilsDeFitness: FitnessPink,
    Barbecue: BarbecuePink,
    BienUnique: bienUniquePink,
    Billard: billardPink,
    Brasero: BraseroPink,
    CamerasDeSurveillance: CamerasDeSurveillancePink,
    Central: CentralPink,
    Cheminee: ChemineePink,
    Climatisation: climatiseurPink,
    Cuisine: cuisinePink,
    DetecteurCo2: detecteurCo2Pink,
    Detecteurdefumee: detecteurFeuxPink,
    DoucheExterieure: doucheExterieurPink,
    Elegant: elegantPink,
    EspaceDeTravail: workspacePink,
    EspaceRepas: lunchAreaPink,
    Extincteur: ExtinguisherPink,
    Jacuzzi: jacuzziPink,
    KitDePremiersSecours: aidePink,
    LaveLinge: MachineLaveePink,
    Paisible: PeacefulPink,
    ParkingGratuit: parkingPink,
    ParkingPayant: parkingPayantPink,
    Patio: patioPink,
    Piano: pianoPink,
    Piscine: poolPink,
    Spacieux: SpacieuxPink,
    KitDePremiersSecours: aidKitPink,
    TV: tvPink,
    WIFI: wifiPink,
    Chauffage: chauffagePink,
    Transport: transportPink,
    Bed: bedPink,
    Monument: MonumentPink,
    Fiber: fiberPink,
    CenterQ: centerQPink,
    EauChaude: eauChaudePink,
    Towels: towelsPink,
    Calme: calmePink,
    NearToSea: NearToSeaPink,
    lavevaisselle: dishwasherPink,
    lingedebain: towelsPink,
    gardien: gardePink,
    rooftop: roofPink,
    livres: booksPink,
    jeux: gamePink,
    jardin: gardenPink,
    baignoire: BaignoirePink,
    espacerepasexterieur: spaceOutDoorPink,
    quartiercommercant: downtownPink,
    Restaurantsetcafes: coffeePink,
    Petitdejeunerdisponible: breakfastPink,
    vuemer: seaPink,
};

export const proprse = {
    vila,
    VillaPink,
    AccesAuLacPink,
    AccesAuLac,
    Brasero,
    BraseroPink,
    aidKit,
    aidKitPink,
    chambre,
    ChambrePink,
    Garage,
    GaragePink,
    Baignoire,
    BaignoirePink,
    Douche,
    DouchePink,
    Eauchaude,
    EauchaudePink,
    Seche,
    SechePink,
    pool,
    poolPink,
    lit,
    Barbecue,
    aide,
    bienUnique,
    billard,
    CamerasDeSurveillance,
    Cheminee,
    climatiseur,
    cuisine,
    detecteurCo2,
    detecteurFeux,
    doucheExterieur,
    elegant,
    Extinguisher,
    familyHouse,
    Fitness,
    jacuzzi,
    lunchArea,
    MachineLavee,
    parkingPayant,
    patio,
    Peaceful,
    piano,
    Spacieux,
    tv,
    tvPink,
    wifi,
    workspace,
    garden,
    gardenPink,
    EquipementPink,
    Equipement,
    aidePink,
    BarbecuePink,
    bienUniquePink,
    billardPink,
    CamerasDeSurveillancePink,
    ChemineePink,
    climatiseurPink,
    cuisinePink,
    detecteurCo2Pink,
    detecteurFeuxPink,
    doucheExterieurPink,
    elegantPink,
    ExtinguisherPink,
    familyHousePink,
    FitnessPink,
    jacuzziPink,
    lunchAreaPink,
    MachineLaveePink,
    parkingPayantPink,
    patioPink,
    PeacefulPink,
    CentralPink,
    Central,
    pianoPink,
    SpacieuxPink,
    wifiPink,
    workspacePink,
    parking,
    parkingPink,
    chauffage,
    chauffagePink,
    transport,
    transportPink,
    bedPink,
    bed,
    Monument,
    MonumentPink,
    fiber,
    fiberPink,
    centerQ,
    centerQPink,
    eauChaude,
    eauChaudePink,
    towels,
    towelsPink,
    calme,
    calmePink,
    NearToSea,
    NearToSeaPink,
    garde,
    gardePink,
    bath,
    bathPink,
    breakfast,
    breakfastPink,
    downtown,
    downtownPink,
    seaPink,
    sea,
    coffeePink,
    coffee,
    gamePink,
    game,
    spaceOutDoorPink,
    spaceOutDoor,
    booksPink,
    books,
    roofPink,
    roof,
    dishwasherPink,
    dishwasher,
};
