import React, {useState, useEffect} from "react";
import {TravelType} from "../../assest/img/Host/HostData";
import gsap from "gsap";
import GrayLine from "../../component/ui/GrayLine";
import {useTranslation} from "react-i18next";

function AddReductions({Reductions, setReductions}) {
    const {t} = useTranslation()
    useEffect(() => {
        gsap.fromTo(
            ".travelType",
            {
                y: 50,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                stagger: 0.05,
            }
        );
    }, []);

    const handleInputChange = (key, value) => {
        setReductions((prevReductions) => ({
            ...prevReductions,
            [key]: {
                ...prevReductions[key],
                value: value,
            },
        }));
    };

    const handleCheckboxChange = (key, isChecked) => {
        setReductions((prevReductions) => ({
            ...prevReductions,
            [key]: {
                ...prevReductions[key],
                isChecked: isChecked,
            },
        }));
    };

    return (
        <div className="w-full items-center flex flex-col gap-5 pb-20">
            <div className="travelType  w-full lg:w-[60%] flex flex-col gap-5">
                <h1 className="text-[1.5rem] md:text-[2rem] font-bold   ">
                    {t('Host.Reductions.DefineYourDiscounts')} <span
                    className="text-sec"> {t('Host.Reductions.Discounts')}</span>
                </h1>
                <p>{t('Host.Reductions.YouCanAlwaysModifyTheDiscounts')}</p>
            </div>

            <div className="inputs     w-full  lg:w-[60%] flex  flex-col lg:flex-row gap-5">
                <label
                    htmlFor="Re7jr"
                    className=" travelType flex cursor-pointer flex-row w-full border-solid border-[1px] rounded-md  items-start  justify-between  h-full  px-5 py-4  "
                >
                    <div className=" flex flex-col gap-3">
                        <h3 className="   font-semibold">{t('Host.Reductions.WeeklyDiscount')}</h3>
                        <p className=" text-sm text-gray-400">
                            {t('Host.Reductions.ForBookingsOfNumNightsOrMore', {num: 7})}
                        </p>
                        <GrayLine/>
                        <div className=" flex flex-col gap-2">
                            <h3 className=" text-sm font-semibold">
                                {t('Host.Reductions.EnterYourPercentageDiscount')}
                            </h3>
                            <input
                                onChange={(e) =>
                                    handleInputChange("re1", parseInt(e.target.value, 10))
                                }
                                value={`${Reductions?.re1?.value}`}
                                type="number"
                                className={`px-3 ${Reductions?.re1?.isChecked ? "border-sec text-sec" : ""}  font-semibold  w-full h-[3.4rem] border-solid  border-[1px] rounded-md`}
                            />
                        </div>
                    </div>
                    <input
                        value={`${Reductions?.re1?.isChecked}` + '%'}
                        onChange={(e) => handleCheckboxChange("re1", e.target.checked)}
                        className="accent-pink-500 mt-4  h-[1rem] w-[1rem]"
                        type="checkbox"
                        checked={Reductions?.re1?.isChecked}
                        name="Re7jr"
                        id="Re7jr"
                    />
                </label>

                <label
                    htmlFor="Re25jr"
                    className="travelType flex cursor-pointer flex-row w-full border-solid border-[1px] rounded-md justify-between items-start  h-full  px-5 py-4  "
                >
                    <div className=" flex flex-col gap-3">
                        <h4 className="  font-semibold"> {t('Host.Reductions.DiscountPerMonth')} </h4>
                        <p className=" text-sm text-gray-400">
                            {t('Host.Reductions.ForBookingsOfNumNightsOrMore', {num: 30})}
                        </p>
                        <GrayLine/>
                        <div className=" flex flex-col gap-2">
                            <h4 className=" text-sm font-semibold">
                                {t('Host.Reductions.EnterYourPercentageDiscount')}
                            </h4>
                            <input
                                name="re2"
                                onChange={(e) => handleInputChange("re2", e.target.value)}
                                value={`${Reductions?.re2?.value}`}
                                type="number"
                                className={`px-3 ${Reductions?.re2?.isChecked ? "border-sec text-sec" : ""}  font-semibold  w-full h-[3.4rem] border-solid  border-[1px] rounded-md`}
                            />
                        </div>
                    </div>
                    <input
                        value={`${Reductions?.re2?.isChecked}`}
                        onChange={(e) => handleCheckboxChange("re2", e.target.checked)}
                        className="accent-pink-500 mt-4 h-[1rem] w-[1rem]  "
                        type="checkbox"
                        checked={Reductions?.re2?.isChecked}
                        name="Re25jr"
                        id="Re25jr"
                    />
                </label>
            </div>
        </div>
    );
}

export default AddReductions;
