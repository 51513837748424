import axios from "axios";
import Cookies from "js-cookie";

export const getBeinByUserId = async () => {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    try {
        const response = await axios.post(
            `https://visiontunisie.com/api/v1/GestionDesBiens/SelectBienByIdUser`,
            {
                ID_Utulisateur: UserId,
            },
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );

        return response?.data;
    } catch (error) {
        throw error
    }
};

export default getBeinByUserId;
