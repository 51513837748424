import axios from "axios";
import Cookies from "js-cookie";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

function UpdatePolitiques({roles, setRoles, Slog, ID_Bien}) {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState();

    const sendRequest = async (key, endpoint, newState) => {
        const userToken = Cookies.get("Token");
        const UserId = localStorage.getItem("ID_Utulisateur");

        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/${endpoint}`,
                {
                    Bien: {
                        ID_Bien: ID_Bien,
                        ID_Proprietaire: UserId,
                    },
                    Caracteristiques: {
                        [key]: newState,
                    },
                },

                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleToggle = async (key, endpoint, label) => {
        setIsLoading(key);

        try {
            const newState = !roles[key];
            const data = await sendRequest(key, endpoint, newState);
            setRoles((prevAcceptedItems) => {
                return {
                    ...prevAcceptedItems,
                    [key]: newState,
                };
            });
            toast.success(t("Msgs.Updatesuccessful", {text: label}));
            setIsLoading(null);
        } catch (error) {
            setRoles((prevAcceptedItems) => {
                return {
                    ...prevAcceptedItems,
                    [key]: false,
                };
            });
            setIsLoading(null);
        }
    };

    const handleReject = async (key, endpoint, label) => {
        setIsLoading(key);
        try {
            const newState = false;
            const data = await sendRequest(key, endpoint, newState);
            setRoles((prevAcceptedItems) => {
                return {
                    ...prevAcceptedItems,
                    [key]: newState,
                };
            });
            setIsLoading(null);
            toast.success(t("Msgs.Updatesuccessful", {text: label}));
        } catch (error) {
            setRoles((prevAcceptedItems) => {
                return {
                    ...prevAcceptedItems,
                    [key]: false,
                };
            });
            setIsLoading(null);
        }
    };

    const Items = [
        {
            key: "animauxautorises",
            label: t("Host.Politiques.PetsAllowed"),
            endpoint: "GestionDesBiens/UpdateCaracteristique",
        },
        {
            key: "evenementsautorises",
            label: t("Host.Politiques.Events"),
            endpoint: "GestionDesBiens/UpdateCaracteristique",
        },
        {
            key: "autorisationdefumer",
            label: t("Host.Politiques.Smoking"),
            endpoint: "GestionDesBiens/UpdateCaracteristique",
        },
    ];
    return (
        <div className="  flex flex-col gap-3   w-full lg:w-[50%] ">
            <h1 className="  font-semibold text-lg">
                {" "}
                {t(`Host.Politiques.PropertyPolicies`)} :
            </h1>
            {Items?.map((item, index) => (
                <div key={index} className="z-0 ">
                    <label
                        htmlFor={item.key}
                        className="    z-20 flex  font-semibold flex-row items-center justify-between  w-full  py-5 px-5  travelType  border-solid border-[1px] rounded-md    "
                    >
                        <p> {item.label} </p>
                        <div
                            className={`  ${
                                isLoading == item.key ? " opacity-40" : ""
                            } flex flex-row items-center gap-3`}
                        >
                            <button
                                disabled={isLoading == item.key}
                                onClick={() =>
                                    handleReject(item.key, item.endpoint, item.label)
                                }
                                className={`  ${
                                    !roles[item.key]
                                        ? "bg-sec  text-white"
                                        : "text-gray-400 border-solid border-[1px] "
                                } p-2 px-3  rounded-md   `}
                            >
                                {t("Actions.No")}
                            </button>

                            <button
                                disabled={isLoading == item.key}
                                onClick={() =>
                                    handleToggle(item.key, item.endpoint, item.label)
                                }
                                className={`  p-2 px-3  ${
                                    roles[item.key]
                                        ? "bg-sec  text-white"
                                        : "text-gray-400 border-solid border-[1px]"
                                }   rounded-md `}
                            >
                                {t("Actions.Yes")}
                            </button>
                        </div>
                    </label>
                </div>
            ))}
        </div>
    );
}

export default UpdatePolitiques;
