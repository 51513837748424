import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Modal from "../../ui/Modal";
import CloseIcon from "@mui/icons-material/Close";
import locationSvg from "../../../assest/img/location.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import getLatLngFromAddress from "../../../API/GetLatAndLanByAddress";
import {gouvernorats} from "../../../assest/gouvernorats";
import Map from "../../DetailsPage/Map";
import SearchLocation from "../../Host/SearchLocation";
import {useTranslation} from "react-i18next";

function UpdateLocation({
                            isOpen,
                            setIsOpen,
                            update,
                            HandelUpdateLocation,
                            LngAndLatOfMap,
                            setLngAndLatOfMap,
                            setAddressDetails,
                            addressDetails,
                            Location,
                            setLocation,
                        }) {
    const [SearchLcationOpen, setSearchLcationOpen] = useState(false);
    const [LngAndLatOfSearch, setLngAndLatOfSearch] = useState(null);
    const [MapDragSelect, setMapDragSelect] = useState(false);

    const apiKey = "AIzaSyD0mFEe70wKfw9RtgZpzlvNPfzwWft3jMk";
    const {t} = useTranslation();
    const navTo = useNavigate();
    const [Delegations, setDelegations] = useState([]);

    useEffect(() => {
        setDelegations(gouvernorats[addressDetails.Gouvernorat] || []);
    }, [addressDetails]);

    const getAllGouvernoratKeys = (obj) => {
        return Object.keys(obj);
    };

    const GouvernoratKeys = getAllGouvernoratKeys(gouvernorats);

    const isButtonDisabled =
        !Location ||
        !addressDetails.streetAddress ||
        !addressDetails.Delegation ||
        !addressDetails.Gouvernorat ||
        !addressDetails.country;

    const handleGetCoordinates = async () => {
        const {country, streetAddress, Gouvernorat, Delegation, postalCode} =
            addressDetails;
        const fullAddress = `${streetAddress}, ${Gouvernorat}, ${Delegation}, ${country} ${postalCode}`;

        try {
            const result = await getLatLngFromAddress(fullAddress, apiKey);
            const accuracy = result?.geometry?.location_type;
            const location = result?.geometry?.location;
            if (accuracy === "ROOFTOP" || accuracy === "RANGE_INTERPOLATED") {
                setLngAndLatOfMap({
                    lat: location.lat,
                    lng: location.lng,
                });
            } else {
                setLngAndLatOfMap({
                    lat: location?.lat,
                    lng: location?.lng,
                });
                setMapDragSelect(true);
            }
        } catch (error) {
            console.error("Error retrieving location:", error);
        }
    };

    async function HandselBecomeHost() {
        await handleGetCoordinates();

        if (addressDetails && LngAndLatOfMap) {

            const savedData = {
                addressDetails,
                LngAndLatOfMap,
            };
            localStorage.setItem("savedData", JSON.stringify(savedData));
            setMapDragSelect(false);

            if (update) {
                HandelUpdateLocation(LngAndLatOfMap, addressDetails);
                setIsOpen(false);
            } else {
                navTo("/become-host/property-type"); // Redirect only on accurate location
            }
        }
    }

    function HandelClose() {
        setIsOpen(false);
    }

    const handleInputChange = (field, value) => {
        setAddressDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    return (
        <>
            <Modal
                onClose={HandelClose}
                isOpen={isOpen}
                isFullPage={true}
                Style={"  w-full md:w-[40%]  h-full md:h-[90%] "}
            >
        <span
            className="  md:text-base text-sm  flex  flex-row-reverse md:flex-row text-center items-center text-main  border-solid border-b-2 md:text-white  justify-center px-[1rem]  md:px-[1.5rem] absolute top-0 right-0  md:bg-main h-[4rem] w-full rounded-t-md ">
          {SearchLcationOpen
              ? t("Host.Map.AddYourAddress")
              : t("Host.Map.EditLogementAdresse")}
            <button
                className="  absolute top-4 left-2"
                onClick={() =>
                    SearchLcationOpen
                        ? setSearchLcationOpen(!SearchLcationOpen)
                        : setIsOpen(!isOpen)
                }
            >
            {SearchLcationOpen ? <KeyboardBackspaceIcon/> : <CloseIcon/>}
          </button>
        </span>

                <div className=" w-full overflow-auto pb-10  px-[0rem] md:px-[2rem]  text-left flex flex-col">
                    <div className="  pt-[5.5rem] w-full flex flex-col gap-[1.5rem] ">
                        <div className=" flex flex-col text-gray-400 ">
                            {!MapDragSelect && (
                                <SearchLocation
                                    IsUpdate={true}
                                    setLngAndLatOfSearch={setLngAndLatOfSearch}
                                    setAddressDetails={setAddressDetails}
                                    setLocation={setLocation}
                                    addressDetails={addressDetails}
                                />
                            )}

                            {!MapDragSelect
                                ? Object.entries(addressDetails).map(([field, value]) => (
                                    <div
                                        className={`${field == "streetAddress" && " hidden"}`}
                                        key={field}
                                    >
                                        {t(`Labels.${field}`)}:
                                        {field === "Gouvernorat" || field === "Delegation" ? (
                                            <select
                                                className={` border-solid cursor-pointer mt-[.1rem]  rounded-md w-full h-[3rem] px-3 font-semibold border-[1px]`}
                                                value={value}
                                                onChange={(e) =>
                                                    handleInputChange(field, e.target.value)
                                                }
                                            >
                                                <option>
                                                    <h2>
                                                        {t("Actions.Select") + " "} {t(`Labels.${field}`)}
                                                    </h2>
                                                </option>
                                                {(field === "Gouvernorat"
                                                        ? GouvernoratKeys
                                                        : Delegations
                                                ).map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                className={`   border-solid mt-[.1rem] text-main rounded-md w-full h-[3rem] px-3 font-semibold border-[1px]`}
                                                type="text"
                                                value={value}
                                                onChange={(e) =>
                                                    handleInputChange(field, e.target.value)
                                                }
                                            />
                                        )}
                                    </div>
                                ))
                                : Location &&
                                MapDragSelect && (
                                    <Map
                                        LngAndLatOfSearch={LngAndLatOfSearch}
                                        LngAndLatOfMap={LngAndLatOfMap}
                                        Ishost={true}
                                        setLngAndLatOfMap={setLngAndLatOfMap}
                                    />
                                )}
                        </div>
                    </div>

                    <div
                        className={` absolute    md:border-0 border-t-2 border-solid bg-white w-full flex items-center ${
                            update ? " justify-between" : "md:justify-end justify-center"
                        }   bottom-0 right-0 px-[0rem] md:px-[2.3rem]   md:pb-4  py-2`}
                    >
                        {update && (
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className={` ${
                                    !Location ? " opacity-50" : " opacity-100"
                                }  text-gray-400 py-2 px-10 rounded-lg   border-[1px] font-semibold`}
                            >
                                {t("Actions.Cancel")}
                            </button>
                        )}
                        <button
                            onClick={HandselBecomeHost}
                            disabled={isButtonDisabled}
                            className={`${
                                isButtonDisabled ? "opacity-50" : "opacity-100"
                            } bg-main py-2 px-10 rounded-lg text-white font-semibold`}
                        >
                            {t("Actions.Continue")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UpdateLocation;
