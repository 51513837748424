import {React, useEffect, useState} from "react";
import gsap from "gsap";
import axios from "axios";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import "../../App.css";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import AiButton from "../BecomeHost/AiButton";
import SuperHostAiAnalyse from "../BecomeHost/SuperHostAiAnalyse";

function TitleAndDescription({
                                 Title,
                                 Slog,
                                 Description,
                                 setDescription,
                                 setTitle,
                                 BienInfo,
                             }) {
    const [descLength, setDescLength] = useState(0);
    const [DescEnLength, setDescEnLength] = useState(0);
    const [DescEsLength, setDescEsLength] = useState(0);
    const [TitlrLength, setTitlrLength] = useState(0);

    const [NewTitle, setNewTitle] = useState(null);

    const [EditTitle, setEditTitle] = useState(false);
    const [EditDisc, setEditEditDisc] = useState(false);
    const [EditDiscEn, setEditDiscEn] = useState(false);
    const [EditDiscEs, setEditDiscEs] = useState(false);

    const [IsModalOpen, setIsModalOpen] = useState(false);
    const {t} = useTranslation();
    useEffect(() => {
        // Perform the character count functions when the component mounts
        setTitlrLength(Title?.length);
        setDescLength(Description?.description?.length);
        setDescEnLength(Description?.descriptionEn?.length);
        setDescEsLength(Description?.descriptionEs?.length);
    }, [Description, Title]);

    const {id} = useParams();

    useEffect(() => {
        setNewTitle(Title);
    }, [Title]);

    const handleTitleChange = (event) => {
        const value = event.target.value;
        setNewTitle(value);
        const characterCount = value?.length;
        setTitlrLength(characterCount);
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        setDescription((prev) => ({
            ...prev,
            description: event.target.value,
        }));
        const characterCount = value?.length;
        setDescLength(characterCount);
    };

    const handleDescriptionEnChange = (event) => {
        setDescription((prev) => ({
            ...prev,
            descriptionEn: event.target.value,
        }));
        const value = event.target.value;
        const characterCount = value?.length;
        setDescEnLength(characterCount);
    };

    const handleDescriptionEsChange = (event) => {
        setDescription((prev) => ({
            ...prev,
            descriptionEs: event.target.value,
        }));
        const value = event.target.value;
        const characterCount = value?.length;
        setDescEsLength(characterCount);
    };

    function updateDescription(endpointUrl, descriptionKey, value, setState) {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const updateTitreData = {
            Slog: Slog,
            ID_Proprietaire: UserId,
            [descriptionKey]: value,
        };

        const userToken = Cookies.get("Token");

        axios
            .post(endpointUrl, updateTitreData, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((response) => {
                // Handle the response data here
                setState(false);
                toast.success(
                    t("Msgs.Updatesuccessful", {text: t("Labels.Description")})
                );
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error:", error);
            });
    }

    // Usage examples for different language endpoints
    function updateDescriptionFr() {
        updateDescription(
            "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateDescription",
            "Description",
            Description?.description,
            setEditEditDisc
        );
    }

    function updateDescriptionEn() {
        updateDescription(
            "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateDescriptionAnglais",
            "DescriptionAnglais",
            Description?.descriptionEn,
            setEditDiscEn
        );
    }

    function updateDescriptionEs() {
        updateDescription(
            "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateDescriptionSpanish",
            "DescriptionSpanish",
            Description?.descriptionEs,
            setEditDiscEs
        );
    }

    function UpdateTitle() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        const updateTitreData = {
            Slog: Slog,
            ID_Proprietaire: UserId,
            Titre: NewTitle,
        };

        axios
            .post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateTitre",
                updateTitreData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                // Handle the response data here
                setEditTitle(false);
                setTitle(NewTitle);
                toast.success(t("Msgs.Updatesuccessful", {text: t("Labels.Title")}));
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error:", error);
            });
    }

    const descriptionStyle = {
        wordWrap: "break-word",
        overflowWrap: "break-word",
        wordBreak: "break-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
    };

    return (
        <div className={` flex flex-col h-full  lg:w-[50%] w-full gap-10`}>
            <h1 className=" font-semibold text-xl">
                {" "}
                {t("HostMode.placeDetails.BasicInformation")}{" "}
            </h1>
            {/* title */}
            <div className="inputs flex flex-col  w-full gap-3">
                <h2 className=" font-semibold text-lg">{t("Labels.Title")}</h2>
                {!EditTitle ? (
                    <div
                        className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                        <h3 className="text-left"> {Title} </h3>
                        <button
                            className="font-semibold  cursor-pointer text-sec"
                            onClick={() => setEditTitle(true)}
                        >
                            {t("Actions.Edit")}
                        </button>
                    </div>
                ) : (
                    <div className=" bg-white shadow-lg  border-[1px]  rounded-md flex flex-col gap-3 p-3">
                        <div className="  w-full flex flex-col gap-7 justify-end items-end">
                            <p className=" text-sm font-bold   text-start  w-full">
                                {t("Host.Description&Title.GiveATitleToYourProperty")}
                            </p>
                            <input
                                value={NewTitle}
                                onChange={handleTitleChange}
                                className="w-full px-2 h-[3.4rem] border-[2px] rounded-lg"
                                type="text"
                                placeholder="Titre de votre bien"
                            />
                            <p
                                className={`  text-start w-full text-xs ${
                                    TitlrLength >= 10 ? " text-green-800" : " text-red-600 "
                                }  `}
                            >
                                {t("Host.Description&Title.MinimumCharacters")} {TitlrLength}/10
                            </p>
                        </div>
                        <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                            <button
                                onClick={() => setEditTitle(false)}
                                className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                            >
                                {t("Actions.Cancel")}
                            </button>
                            <button
                                onClick={UpdateTitle}
                                className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                            >
                                {t("Actions.Save")}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* discription */}
            <div className="  flex flex-col  w-full gap-3">
                <h2 className=" font-semibold text-lg"> {t("Labels.Description")} </h2>
                {!EditDisc ? (
                    <div
                        className="  flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                        <p className="text-left"> {Description?.description}</p>
                        <button
                            className=" font-semibold cursor-pointer text-sec"
                            onClick={() => setEditEditDisc(true)}
                        >
                            {t("Actions.Edit")}
                        </button>
                    </div>
                ) : (
                    <div className=" bg-white shadow-lg  border-[1px]  rounded-2xl flex flex-col gap-3 p-3">
                        <div
                            style={{descriptionStyle}}
                            className="inputs flex flex-col  gap-3"
                        >
                            <div className=" flex  flex-col lg:gap-0 gap-3  lg:flex-row justify-between items-center">
                                <p className=" text-sm w-full lg:w-[70%]">
                                    {t("Host.Description&Title.GiveADescriptionToYourProperty")}
                                </p>
                                <AiButton
                                    OnClick={() => setIsModalOpen(!IsModalOpen)}
                                    isAnalyse={true}
                                    setTitleAndDescription={setDescription}
                                    TitleAndDescription={Description}
                                    IsDisabled={Description?.description?.length <= 34}
                                />
                            </div>
                            <div className=" w-full flex flex-col gap-1 justify-end items-end">
                <textarea
                    value={Description?.description}
                    onChange={handleDescriptionChange}
                    className="border-[2px] rounded-lg  w-full p-2"
                    name=""
                    placeholder={
                        t("Host.Description&Title.PropertyDescription") +
                        " (Français)"
                    }
                    id=""
                    cols="30"
                    rows="10"
                ></textarea>
                                <p
                                    className={`text-sm ${
                                        descLength >= 34 ? " text-green-800" : " text-red-600 "
                                    }`}
                                >
                                    {t("Host.Description&Title.MinimumCharacters")} {descLength}
                                    /34
                                </p>
                            </div>
                        </div>
                        <SuperHostAiAnalyse
                            TitleAndDescription={Description}
                            IsModalOpen={IsModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            setTitleAndDescription={setDescription}
                            BienInfo={BienInfo}
                        />
                        <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                            <button
                                onClick={() => setEditEditDisc(false)}
                                className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                            >
                                {t("Actions.Cancel")}
                            </button>
                            <button
                                onClick={updateDescriptionFr}
                                className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                            >
                                {t("Actions.Save")}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* englich version */}

            <div className="inputs flex flex-col  w-full gap-3">
                <h3 className=" font-semibold text-lg">
                    {t("Labels.Description")} (English){" "}
                </h3>
                {!EditDiscEn ? (
                    <div
                        className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                        <h3 className="text-left">
                            {" "}
                            {Description?.descriptionEn || "not provided yet"}{" "}
                        </h3>
                        <button
                            className="font-semibold  cursor-pointer text-sec"
                            onClick={() => setEditDiscEn(true)}
                        >
                            {t("Actions.Edit")}
                        </button>
                    </div>
                ) : (
                    <div className=" bg-white shadow-lg  border-[1px]  rounded-md flex flex-col gap-3 p-3">
                        <div className="inputs flex flex-col  w-full gap-3 ">
                            <div className=" flex  flex-col lg:gap-0 gap-3  lg:flex-row justify-between items-center">
                                <p className=" text-sm w-full lg:w-[70%]">
                                    {t(
                                        "Host.Description&Title.GiveADescriptionToYourPropertyInEn"
                                    )}
                                </p>
                                <span className=" lg:w-fit w-full flex justify-end items-end">
                  <AiButton
                      isEnglish={true}
                      isItEmpty={Description?.descriptionEn?.length >= 34}
                      setTitleAndDescription={setDescription}
                      TitleAndDescription={Description}
                      IsDisabled={Description?.description?.length <= 34}
                  />
                </span>
                            </div>
                            <div className=" w-full flex flex-col gap-1 justify-end items-end">
                <textarea
                    value={Description?.descriptionEn}
                    onChange={handleDescriptionEnChange}
                    className="border-[2px] rounded-lg  w-full p-2"
                    name=""
                    placeholder={
                        t("Host.Description&Title.PropertyDescription") +
                        " (English)"
                    }
                    id=""
                    cols="30"
                    rows="10"
                ></textarea>
                                <p
                                    className={`text-sm ${
                                        DescEnLength >= 34 ? " text-green-800" : " text-red-600 "
                                    }  `}
                                >
                                    {t("Host.Description&Title.MinimumCharacters")} {DescEnLength}
                                    /34
                                </p>
                            </div>
                        </div>
                        <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                            <button
                                onClick={() => setEditDiscEn(false)}
                                className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                            >
                                {t("Actions.Cancel")}
                            </button>
                            <button
                                onClick={updateDescriptionEn}
                                className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                            >
                                {t("Actions.Save")}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* spanish version */}

            <div className="inputs flex flex-col  w-full gap-3">
                <h2 className=" font-semibold text-lg">
                    {t("Labels.Description")} (Español){" "}
                </h2>
                {!EditDiscEs ? (
                    <div
                        className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                        <h3 className="text-left">
                            {" "}
                            {Description?.descriptionEs || "not provided yet"}{" "}
                        </h3>
                        <button
                            className="font-semibold  cursor-pointer text-sec"
                            onClick={() => setEditDiscEs(true)}
                        >
                            {t("Actions.Edit")}
                        </button>
                    </div>
                ) : (
                    <div className=" bg-white shadow-lg  border-[1px]  rounded-md flex flex-col gap-3 p-3">
                        <div className=" w-full flex flex-col gap-3">
                            <div className="inputs flex flex-col  w-full gap-3">
                                <div
                                    className=" flex  flex-col lg:gap-0 gap-3  lg:flex-row justify-between items-center">
                                    <p className=" text-sm w-full lg:w-[70%]">
                                        {t(
                                            "Host.Description&Title.GiveADescriptionToYourPropertyInEs"
                                        )}
                                    </p>
                                    <span className="  lg:w-fit w-full flex justify-end items-end">
                    <AiButton
                        isEnglish={false}
                        isItEmpty={Description?.descriptionEs?.length >= 34}
                        setTitleAndDescription={setDescription}
                        TitleAndDescription={Description}
                        IsDisabled={Description?.description?.length <= 34}
                    />
                  </span>
                                </div>
                                <div className=" w-full flex flex-col gap-1 justify-end items-end">
                  <textarea
                      value={Description?.descriptionEs}
                      onChange={handleDescriptionEsChange}
                      className="border-[2px] rounded-lg  w-full p-2"
                      name=""
                      placeholder={
                          t("Host.Description&Title.PropertyDescription") +
                          " (Español)"
                      }
                      id=""
                      cols="30"
                      rows="10"
                  ></textarea>
                                    <p
                                        className={`text-sm ${
                                            DescEsLength >= 34 ? " text-green-800" : " text-red-600 "
                                        }  `}
                                    >
                                        {t("Host.Description&Title.MinimumCharacters")}{" "}
                                        {DescEsLength}
                                        /34
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                            <button
                                onClick={() => setEditDiscEs(false)}
                                className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                            >
                                {t("Actions.Cancel")}
                            </button>
                            <button
                                onClick={updateDescriptionEs}
                                className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                            >
                                {t("Actions.Save")}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TitleAndDescription;
