import React, {useEffect, useRef, useState} from "react";
import locationicon from "../../assest/img/location.svg";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import "../../App.css";
import GrayLine from "../ui/GrayLine";
import {useClickOutside} from "../../hooks/UseClickOutSide";
import {useTranslation} from "react-i18next";

function SearchLocation({
                            IsUpdate,
                            setLocation,
                            addressDetails,
                            update,
                            setAddressDetails,
                            setLngAndLatOfSearch,
                        }) {
    const [address, setAddress] = useState("");
    const [NewAddress, setNewAddress] = useState("");
    const [InputFoucs, setInputFoucs] = useState(false);
    const InputRef = useRef();

    const {t} = useTranslation();


    useEffect(() => {
        if (!IsUpdate) return;
        if (!address) {
            setAddress(addressDetails?.streetAddress);
        }
    }, [addressDetails]);

    const handleInputFocus = () => {
        setInputFoucs(true);
    };
    useClickOutside(InputRef, () => setInputFoucs(false));

    const handleChange = (newAddress) => {
        setAddress(newAddress);
    };

    useEffect(() => {

            setAddressDetails({
                ...addressDetails,
                streetAddress: address,
            });
        }, [address]
    )

    const handleSelect = (selectedAddress) => {
        geocodeByAddress(selectedAddress)
            .then((results) => {
                const result = results[0];
                const countryComponent = result.address_components.find((component) =>
                    component.types.includes("country")
                );
                const streetAddressComponent = result.address_components.find(
                    (component) => component.types.includes("street_address")
                );
                const cityComponent = result.address_components.find(
                    (component) =>
                        component.types.includes("locality") ||
                        component.types.includes("administrative_area_level_2")
                );
                const provinceComponent = result.address_components.find((component) =>
                    component.types.includes("administrative_area_level_1")
                );
                const postalCodeComponent = result.address_components.find(
                    (component) => component.types.includes("postal_code")
                );
                setAddressDetails({
                    country: countryComponent ? countryComponent.long_name : "",
                    streetAddress: result
                        ? result.formatted_address : "",
                    Gouvernorat: cityComponent ? cityComponent.long_name : "",
                    Delegation: provinceComponent ? provinceComponent.long_name : "",
                    postalCode: postalCodeComponent ? postalCodeComponent.long_name : "",
                });

                getLatLng(result).then((latLng) => {
                    setLngAndLatOfSearch({
                        lat: latLng.lat,
                        lng: latLng.lng,
                    });
                });
            })
            .catch((error) => console.error("Error", error));

        // Update the input value with the selected suggestion
        setAddress(selectedAddress);
        setLocation(selectedAddress);
    };

    return (
        <div
            className={`flex flex-col w-full h-full relative  ${
                IsUpdate ? "" : "pb-10 px-[1rem] pt-[5.5rem]"
            } `}
        >
            <h1 className="  pb-2 ">
                {" "}
                {IsUpdate ? t(`Labels.streetAddress`) : t("Host.Map.TypeYourAddress")}:
            </h1>
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div className=" relative w-full  bg-white">
                        <input
                            ref={InputRef}
                            value={address}
                            onFocus={handleInputFocus}
                            style={{
                                height: "3rem",
                                width: "100%",
                                border: " solid 1px",
                                borderRadius: "5px",
                                paddingLeft: "1rem",
                            }}
                            className="w-full h-16 border-gray-100 my-custom-input-style"
                            {...getInputProps({
                                placeholder: IsUpdate
                                    ? t(`Labels.streetAddress`)
                                    : t("Host.Map.SearchMyAccommodation"),
                                className: "location-search-input",
                            })}
                        />
                        <div
                            className={`  ${
                                IsUpdate ? "absolute w-full shadow-xl    top-10 right-0 " : ""
                            } gap-4 w-full     autocomplete-dropdown-container `}
                        >
                            {loading && (
                                <div
                                    className={`${
                                        IsUpdate ? "absolute w-full shadow-md top-10 right-0" : ""
                                    }`}
                                >
                                    Loading...
                                </div>
                            )}
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                                return (
                                    <div
                                        onClick={() => {
                                            setAddress(suggestion.description);
                                        }}
                                        className={`  py-6 w-full`}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                    <span
                        style={{
                            height: "2.5rem",
                            padding: "1rem",
                        }}
                        className={`  bg-white flex flex-row items-center hover:bg-gray-100 gap-3    py-8  text-start `}
                    >
                      <img
                          className=" h-[1.2rem] "
                          src={locationicon}
                          alt="locationicon"
                      />
                        {suggestion.description}
                    </span>
                                        <GrayLine/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
}

export default SearchLocation;
