import React from "react";
import NoUser from "../../assest/img/noUser.webp";

function Avatar({img, text, HasReservation, Nom, Prenom}) {
    return (
        <div className=" flex   font-semibold gap-2 items-center">
            {HasReservation == 1 ? (
                <img
                    className=" object-cover rounded-[50%]  h-[2.7rem] w-[2.7rem] lg:h-[3rem]  lg:w-[3rem] "
                    src={img ? img : NoUser}
                    alt="avatar"
                />
            ) : (
                <span className="text-2xl bg-white font-bold">
          {Nom?.[0]?.toUpperCase()}
                    {Prenom?.[0]?.toUpperCase()}
        </span>
            )}

            {text}
        </div>
    );
}

export default Avatar;
