import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import TransES from '../src/Lang/es.json';
import TransEN from '../src/Lang/en.json';
import LanguageDetector from "i18next-browser-languagedetector";
import TransFR from '../src/Lang/fr.json';

const resources = {
    en: {
        translation: TransEN,
    },
    es: {
        translation: TransES,
    },
    fr: {
        translation: TransFR,
    },
};

// Get the language from localStorage or use "en" as the default
const savedLanguage = localStorage.getItem("language") || "en";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage, // Use the language from localStorage
        interpolation: {
            escapeValue: false,
        },
        React: {
            useSuspense: false,
        },
    });

// Update localStorage whenever the language changes
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
});

export default i18n;
