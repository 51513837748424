import React, {useState, useEffect} from "react";
import {TravelType} from "../../../assest/img/Host/HostData";
import gsap from "gsap";
import logo from "../../../assest/img/logo.svg";
import {PropertyImg} from "../../../assest/img/Host/HostData";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie"

function UpdateTypeBein({setType, Type, Slog}) {
    const [SeeContent, setSeeContent] = useState(null);

    const isMobile = useMediaQuery({maxWidth: 767});
    const [EditType, setEditType] = useState(false);
    const [NewType, setNewType] = useState();
    const {t} = useTranslation()
    const {id} = useParams();

    useEffect(() => {
        setNewType(Type);
    }, [Type, EditType]);

    async function SaveTypeVoyage() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        axios
            .post("https://visiontunisie.com/api/v1/GestionDesBiens/UpdateTypeDeLogement", {
                    Slog: Slog,
                    ID_Proprietaire: UserId,
                    TypeDeLogement: NewType,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
            .then((response) => {
                setType(NewType);
                setEditType(false);
                toast.success(t("Msgs.Updatesuccessful", {text: t('Labels.PropertyType')}));
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const Types = [
        {
            title: t("Labels.Villa"),
            value: "Villa",
            img: PropertyImg.Vila,
            Active: PropertyImg.VilaPink,
        },
        {
            title: t("Labels.Apartement"),
            value: "Apartement",
            img: PropertyImg.Apartement,
            Active: PropertyImg.ApartementPink,
        },
        {
            title: t("Labels.Chambre"),
            value: "Chambre",
            img: PropertyImg.Chambre,
            Active: PropertyImg.ChambrePink,
        },
        {
            title: t("Labels.Chalet"),
            value: "Chalet",
            img: PropertyImg.Chalet,
            Active: PropertyImg.ChaletPink,
        },
    ];

    return (
        <div
            className={`flex flex-col gap-5  ${
                EditType ? " w-full" : "  w-full lg:w-[50%]"
            } `}
        >
            <div className=" flex flex-col gap-2 px-3 ">
                <div className="inputs flex flex-col  w-full gap-3">
                    <h1 className=" font-semibold text-lg">{t("Labels.PropertyType")}</h1>
                    {!EditType ? (
                        <div
                            className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
                            <h2 className="text-left">{t(`Labels.${Type}`)}</h2>
                            <button
                                className="font-semibold  cursor-pointer text-sec"
                                onClick={() => setEditType(true)}
                            >
                                {t("Actions.Edit")}
                            </button>
                        </div>
                    ) : (
                        <div className=" bg-white shadow-lg w-fit  border-[1px]  rounded-md   flex flex-col gap-3 p-3">
                            <div className="  flex-col flex  items-center     h-full ">
                                <div
                                    className=" h-[60%]  lg:h-[70%]   p-0   lg:p-10   py-5 grid grid-cols-2 gap-[1rem]    ">
                                    {Types.map((item) => (
                                        <div className="type">
                      <span
                          onClick={() => setNewType(item.value)}
                          className={` ${
                              NewType == item.value ? " border-sec text-sec " : " "
                          }  w-full text-xl font-bold flex flex-col p-5  lg:p-10  h-full cursor-pointer hover:shadow-md duration-200 border-solid border-2 rounded-lg gap-4`}
                      >
                        <img
                            src={NewType == item.value ? item.Active : item.img}
                            className={`  w-[3.4rem] md:w-[3.6rem]  h-[3.4rem]  md:h-[3.6rem] `}
                            alt={item?.title}
                        />
                        <p>{item?.title}</p>
                      </span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
                                <button
                                    onClick={() => setEditType(false)}
                                    className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
                                >
                                    {t("Actions.Cancel")}
                                </button>
                                <button
                                    onClick={SaveTypeVoyage}
                                    className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
                                >
                                    {t("Actions.Save")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateTypeBein;
