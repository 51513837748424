import React, {useContext, useState} from "react";
import {Usercontext} from "../../context/Usercontexte";
import {useClickOutside} from "../../hooks/UseClickOutSide";
import {useRef} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Currency() {
    const {currency, setCurrency} = useContext(Usercontext);
    const [isOpen, setIsOpen] = useState(false);
    const OpenRef = useRef();

    useClickOutside(OpenRef, () => setIsOpen(false));

    const Cur = [
        {
            code: "USD",
            symbol: "$",
        },
        {
            code: "EUR",
            symbol: "€",
        },
        {
            code: "TND",
            symbol: "TND",
        },
    ];

    return (
        <div
            ref={OpenRef}
            onClick={() => setIsOpen(!isOpen)}
            className="  pr-2 pl-4 gap-5  py-1  text-sm cursor-pointer border-[1px] flex    rounded-md relative "
        >
      <span className="  flex  items-center gap-1 ">
        {currency.code}
          <KeyboardArrowDownIcon className="   right-0"/>
      </span>
            {isOpen && (
                <span
                    className=" bg-white top-8 right-0   w-full  items-center justify-center shadow-md rounded-md absolute flex flex-col  ">
          {Cur.map((cur) => (
              <span
                  onClick={() => setCurrency(cur)}
                  className={`py-1  w-full  flex items-center hover:bg-gray-100 justify-center ${
                      currency.code == cur.code ? " text-sec bg-gray-50" : ""
                  } `}
              >
              {" "}
                  {cur.code}{" "}
            </span>
          ))}
        </span>
            )}
        </div>
    );
}

export default Currency;
