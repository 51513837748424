import {useState} from "react";

export const UseToggle = () => {
    const [Status, useStatus] = useState();

    const ToggelStatus = () => useStatus((prev) => !prev);
    return {ToggelStatus, Status};
};

 
