import React, {useContext} from "react";
import {Link as ScrollLink} from "react-scroll";

import LanguageIcon from "@mui/icons-material/Language";
import {Link} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {Usercontext} from "../context/Usercontexte";
import Currency from "./Navbar/Currency";
import Lang from "./Navbar/Lang";
import {SocketContext} from "../context/SocketContext";

function SideBar({isOpen, ToggelStatus, dashboard, isDashboard, Msgs}) {
    const {t, i18n} = useTranslation();
    const {User, hasProperty} = useContext(Usercontext);
    const {messages} = useContext(SocketContext);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };


    const Links = dashboard
        ? [
            {
                text: "Calendrier",
                link: "/dashboard/calendrier",
                nav: true,
            },
            {
                text: "Longements",
                link: "/dashboard",
                nav: true,
            },
            // {
            //   text: "Réservations",
            //   link: "/dashboard/Reservations",
            //   nav: true,
            // },
            {
                text: "Revenues",
                link: "/dashboard/revenues",
                nav: true,
            },
            {
                text: "Messages",
                link: "/dashboard/messages",
                nav: true,
                notification: Msgs[Msgs?.length - 1]?.Allunseen_message || messages?.length,
            },
        ]
        : [
            {
                text: "Home",
                link: "/",
                nav: true,
            },
            {
                text: t("NavBar.Accommodation"),
                link: "/search-by-map",
                nav: true,
            },
            {
                text: t("NavBar.Blog"),
                link: "/blogs",
                nav: true,
            },
            User && {
                link: "/messages",
                text: t("NavBar.MenuBar.Messages"),
                nav: true,
                notification: Msgs && Msgs[1]?.Allunseen_message,
            },
            // {
            //   text: "Map",
            //   link: "/search-by-map",
            //   nav: true,
            // },
        ];
    return (
        <div
            className={` h-[100vh]  z-[200]   flex flex-col justify-center   pb-10 items-center text-4xl   gap-7 w-full fixed top-0 duration-100  ${
                isOpen ? " right-0" : " -right-[100%] "
            } bg-white z-40`}
        >
        <span className="  absolute top-4 right-5  flex  items-center gap-2">
          <Lang/>
          <Currency/>
        </span>

            <button
                onClick={() => ToggelStatus(false)}
                className=" absolute z-[200] cursor-pointer top-3 left-5"
            >
                <CloseIcon className=" scale-125 "/>
            </button>

            {Links?.map((i, index) => (
                <div key={index}>
                    {i?.nav ? (
                        <Link
                            rel="canonical"
                            onClick={() => ToggelStatus(false)}
                            key={index}
                            className=" hover:text-sec"
                            to={i?.link}
                        >
              <span className=" w-fit relative">
                {i?.text}
                  {i?.notification > 0 && (
                      <span
                          className=" text-white bg-sec flex items-center justify-center text-sm h-[1.1rem] w-[1.1rem] top-5 -right-4 absolute rounded-[50%] ">
                    {i?.notification}
                  </span>
                  )}
              </span>
                        </Link>
                    ) : (
                        <ScrollLink
                            smooth={true}
                            duration={500}
                            to={`${i?.link}`}
                            key={index}
                            className=" cursor-pointer hover:text-sec"
                        >
                            {i?.text}{" "}
                        </ScrollLink>
                    )}
                </div>
            ))}
            {User &&
                (!isDashboard ? (
                    <Link className="flex" to="/dashboard">
                        {t("NavBar.HostMode")}
                    </Link>
                ) : (
                    <Link className="flex" to="/Logement">
                        {t("Labels.TravelerMode")}
                    </Link>
                ))}
            <Link
                to="/host"
                className={`group  bg-white absolute  active:scale-95 bottom-[15%] flex ml-4  ${hasProperty ? ' text-2xl  text-main border-main hover:bg-main' : '  border-sec hover:bg-sec text-sec text-3xl'}    h-[5rem] w-[70%]     lg:flex  text-center   hover:text-white     items-center justify-center  rounded-lg   border-solid border-2  `}
            >

                <h1 className="    font-semibold ">{hasProperty ? t("NavBar.MenuBar.createNewListing") : t("NavBar.BecomeHost")}</h1>
            </Link>

            {/* backGround Logo */}
            <span className="    -z-10   absolute  top-[50%]  h-full   -right-44 ">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="396"
            height="400"
            viewBox="0 0 208 280"
            fill="none"
        >
          <path
              d="M200.769 7.24236C196.454 3.08155 191.355 1.00228 185.48 1.00228H184.941C179.059 1.00228 173.962 3.08383 169.65 7.24236C165.33 11.522 163.171 16.6379 163.171 22.5809V92.7115C145.865 80.3388 126.024 74.1192 103.623 74.1192C81.2212 74.1192 61.3804 80.3365 44.0772 92.7092V22.5809C44.0772 16.6379 41.9175 11.5243 37.5981 7.24236C33.2855 3.08155 28.1847 1 22.3094 1H21.7678C15.8902 1 10.794 3.08155 6.47917 7.24008C2.15972 11.522 0 16.6379 0 22.5809V257.988C0 264.052 2.15972 269.106 6.47917 273.148C10.794 277.428 15.8925 279.569 21.7678 279.569H22.3094C28.1824 279.569 33.2832 277.428 37.5981 273.148C41.2191 269.762 43.3005 265.651 43.8859 260.842C43.9989 259.914 44.0772 258.968 44.0772 257.988V177.379C44.0772 160.973 49.8349 147.005 61.3504 135.471C72.9788 123.939 87.0734 118.172 103.623 118.172C120.172 118.172 134.085 123.939 145.72 135.471C157.355 147.005 163.171 160.971 163.171 177.379V257.988C163.171 258.968 163.247 259.914 163.362 260.842C163.948 265.651 166.029 269.762 169.65 273.148C173.965 277.428 179.059 279.569 184.941 279.569H185.48C191.358 279.569 196.454 277.428 200.769 273.148C205.088 269.106 207.248 264.052 207.248 257.988V22.5809C207.248 16.6379 205.088 11.5243 200.769 7.24236Z"
              stroke="#FF5A5F"
              strokeWidth="0.618651"
              strokeMiterlimit="10"
          />
          <g transform="translate(85, 150) scale(.3)">
            <path
                d="M91.2151 15.872C88.4378 7.69113 80.8544 1.70312 71.6242 1.70312C62.3939 1.70312 54.8181 7.69113 52.0407 15.872C23.0025 24.2892 1.60742 50.508 1.60742 81.8361C1.60742 119.92 33.0262 150.894 71.6242 150.894C110.222 150.894 141.648 119.92 141.648 81.8361C141.648 50.508 120.246 24.2966 91.2151 15.872ZM71.6242 131.807C43.6938 131.807 20.9663 109.384 20.9663 81.8361C20.9663 59.9219 35.4368 41.4706 55.372 34.7442C59.1974 39.6099 64.9092 42.9399 71.6242 42.9399C78.3391 42.9399 84.0585 39.6025 87.8838 34.7442C107.827 41.4632 122.29 59.9219 122.29 81.8361C122.29 109.384 99.562 131.807 71.6242 131.807Z"
                stroke="#FF5A5F"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
          </g>
        </svg>
      </span>
        </div>
    );
}

export default SideBar;
