import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import LoadingBtn from "../../ui/LoadingBtn";

function UpdateSelectedDatesRange({
                                      Prix,
                                      setBlokedDates,
                                      setNewPrices,
                                      selectedDates,
                                      setSelectedSlots,
                                      BlokedDates,
                                      Slog,
                                      NewPrices,
                                  }) {
    const [NewPrice, setNewPrice] = useState(Prix);
    const [Bloked, setBloked] = useState("");
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    // Loading
    const [NewPriceLoading, setNewPriceLoading] = useState(false);
    const [BlokLoading, setBlokLoading] = useState(false);

    const [BlockOrDeBlock, setBlockOrDeBlock] = useState("BlockAll");
    const [prices, setPrices] = useState({});

    const {t} = useTranslation();

    useEffect(() => {
        setNewPrice(Prix);
    }, [Prix]);

    useEffect(() => {
        // Iterate through selected dates
        const selectedPrices = selectedDates.map((date) => {
            // Find the price for each selected date
            const priceForSelectedDate = NewPrices.find(
                (item) => item.DateNewPrice === date
            );

            // If price is found, return the price, otherwise return the default price
            return priceForSelectedDate ? priceForSelectedDate.NouveauPrix : Prix;
        });

        // Remove duplicates from selected prices
        const uniquePricesSet = new Set(
            selectedPrices.map((price) => String(price).trim())
        ); // Convert prices to strings and remove duplicates
        const uniquePrices = [...uniquePricesSet]; // Convert Set back to an array

        // Set the unique prices to the state
        setPrices(uniquePrices);
    }, [selectedDates]);


    const {id} = useParams();

    useEffect(() => {
        const overlappingDates = selectedDates?.filter((date) =>
            BlokedDates?.includes(date)
        );
        if (overlappingDates?.length === selectedDates?.length) {
            // All selected dates are blocked
            setBloked("Blockeddates");
        } else if (overlappingDates?.length > 0) {
            // Some selected dates are blocked
            setBloked("Some");
        } else {
            // All selected dates are normal
            setBloked("Normaldates");
        }
    }, [selectedDates, BlokedDates]);

    async function BlokDates() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        setBlokLoading(true);
        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/GestionDesBiens/blockCalendar`,
                {
                    Slog: Slog,
                    BlockDate: selectedDates,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            toast.success(t("Msgs.blockedDates"));
            setBlokLoading(false);
            setSelectedSlots([]);
            setBlokedDates((prevDates) => [...prevDates, ...selectedDates]);
        } catch (error) {
            setBlokLoading(false);
            throw new Error(`Error fetching geocoding data: ${error.message}`);
        }
    }

    async function DeBlokDates() {
        setBlokLoading(true);
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/GestionDesBiens/DeblockCalendar`,
                {
                    Slog: Slog,
                    BlockDate: selectedDates,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setBlokLoading(false);

            // Filter out selectedDates from BlokedDates
            const updatedBlokedDates = BlokedDates.filter(
                (date) => !selectedDates.includes(date)
            );

            // Update BlokedDates state with the filtered dates
            toast.success(t("Msgs.UnBlockedDates"));
            setSelectedSlots([]);

            setBlokedDates(updatedBlokedDates);
        } catch (error) {
            setBlokLoading(false);

            throw new Error(`Error fetching geocoding data: ${error.message}`);
        }
    }

    async function UpdateBlockedOrDeBlokedDates() {
        if (BlockOrDeBlock == "BlockAll") {
            await BlokDates();
        } else {
            await DeBlokDates();
        }
    }

    async function UpdatesNewPrice() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        setNewPriceLoading(true);
        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateSpecialPrice`,
                {
                    Slog: Slog,
                    DateNewPrice: selectedDates,
                    NouveauPrix: prices[0],
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            toast.success(t("Msgs.Updatesuccessful", {text: t("Labels.Price")}));
            setNewPriceLoading(false);
            setSelectedSlots([]);

            for (const date of selectedDates) {
                // Find if the date already exists in NewPrices
                const existingIndex = NewPrices.findIndex(
                    (item) => item.DateNewPrice === date
                );

                if (existingIndex !== -1) {
                    // If the date exists, update its NouveauPrix
                    setNewPrices((prevPrices) => {
                        const updatedPrices = [...prevPrices];
                        updatedPrices[existingIndex] = {
                            DateNewPrice: date,
                            NouveauPrix: prices[0],
                        };
                        return updatedPrices;
                    });
                } else {
                    // If the date doesn't exist, add it to NewPrices
                    setNewPrices((prevPrices) => [
                        ...prevPrices,
                        {DateNewPrice: date, NouveauPrix: prices[0]},
                    ]);
                }
            }
        } catch (error) {
            setNewPriceLoading(false);
            throw new Error(`Error fetching geocoding data: ${error.message}`);
        }
    }

    return (
        <div className=" flex flex-col gap-4 overflow-auto pt-[4.7rem] w-full px-5">
            <div className=" w-full ">
                {Bloked == "Some" ? (
                    <div
                        className={`flex flex-col items-center gap-2 text-sm justify-center  border-[1px] duration-200 ${
                            isDropDownOpen ? " rounded-[1rem] " : " rounded-[2rem] "
                        }  pb-2  pt-4 text-center `}
                    >
            <span
                className=" w-full h-full cursor-pointer"
                onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            >
              {t("HostMode.Calendrier.SomeOfTheDatesAreBlocked")}
            </span>
                        <div
                            className={` w-full flex flex-col justify-between  duration-200 ${
                                isDropDownOpen ? "h-[10rem] p-4 " : " p-0 h-[0rem]"
                            }   `}
                        >
              <span
                  className={`w-full  h-[10rem] justify-between flex ${
                      isDropDownOpen ? " " : "   opacity-0"
                  } flex-col `}
              >
                <span className=" flex gap-4 flex-col ">
                  <span className=" font-semibold w-full flex justify-between px-2">
                    {t("Actions.BlockAll")}
                      <input
                          checked={BlockOrDeBlock == "BlockAll"}
                          onChange={(e) => setBlockOrDeBlock("BlockAll")}
                          value={BlockOrDeBlock}
                          className=" h-[1rem] w-[1rem] accent-sec "
                          type="radio"
                      />
                  </span>
                  <span className=" font-semibold w-full flex justify-between px-2">
                    {t("Actions.UnblockAll")}
                      <input
                          checked={BlockOrDeBlock == "DeBlockAll"}
                          onChange={(e) => setBlockOrDeBlock("DeBlockAll")}
                          value={BlockOrDeBlock}
                          className=" h-[1rem] w-[1rem] accent-sec "
                          type="radio"
                      />
                  </span>
                </span>

                <button
                    disabled={BlokLoading}
                    onClick={UpdateBlockedOrDeBlokedDates}
                    className={` ${
                        BlokLoading ? " opacity-80" : ""
                    } relative  w-full py-3 rounded-md   text-white bg-main`}
                >
                  {t("Actions.Save")}
                    <LoadingBtn scale={"scale-[.15] "} isLoading={BlokLoading}/>
                </button>
              </span>
                        </div>
                    </div>
                ) : (
                    <div
                        className=" font-semibold rounded-full    bg-gray-100 w-full relative px-3 justify-between py-2  h-[4rem] flex flex-row ">
                        {BlokLoading && (
                            <span className=" z-50 bg-gray-100 absolute top-0 right-0 w-full h-full rounded-full">
                <LoadingBtn
                    scale={"scale-[.15] "}
                    BgColor={"white"}
                    color={"gray"}
                    isLoading={true}
                />
              </span>
                        )}
                        <button
                            onClick={() => {
                                DeBlokDates();
                            }}
                            className={`   ${
                                Bloked == "Normaldates" ? " text-white" : " text-gray-700 "
                            } z-10 text-center    rounded-full px-4`}
                        >
                            {Bloked == "Blockeddates"
                                ? t("Actions.Unblock")
                                : t("Labels.Availabilities")}
                        </button>

                        <button
                            onClick={() => {
                                BlokDates();
                            }}
                            className={`  z-10 ${
                                Bloked == "Normaldates" ? "text-gray-700 " : " text-white"
                            } text-center px-4`}
                        >
                            {t("Actions.BlockDates")}
                        </button>
                        <span
                            className={`h-full bg-gray-500 absolute ${
                                Bloked == "Normaldates" ? "left-[0%]" : "left-[50%]"
                            } w-[50%] top-0   duration-150  rounded-full`}
                        ></span>
                    </div>
                )}
            </div>
            {/* New price */}
            <span className=" relative">
        <label
            className=" absolute top-1 left-5 font-medium text-sm "
            htmlFor=""
        >
          {t("Labels.PricePerNight")}
        </label>
        <input
            onChange={(e) => {
                const value = e.target.value;
                // Check if the value is empty
                if (value === "") {
                    // If empty, set prices to an empty array
                    setPrices([]);
                } else {
                    // If not empty, split the string into an array of prices
                    setPrices(value.split("-"));
                }
            }}
            value={prices.length ? prices.join("-") : ""}
            className=" w-full h-[5.5rem] text-[4rem] font-bold px-4 border-2  rounded-xl "
            type={prices?.length >= 2 ? "text" : "number"}
        />
      </span>

            <div className="   lg:relative      py-3 flex w-full justify-between gap-4  font-semibold ">
                <button
                    disabled={prices?.length >= 2}
                    onClick={UpdatesNewPrice}
                    className={` ${
                        prices?.length >= 2 && " opacity-50"
                    } relative   w-full py-3 rounded-md   text-white bg-main`}
                >
                    <LoadingBtn scale={"scale-[.15] "} isLoading={NewPriceLoading}/>
                    {t("Actions.Save")}
                </button>
            </div>
        </div>
    );
}

export default UpdateSelectedDatesRange;
