import React, {useState, useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

function UpdateTypeOfBeinMorP({Visibilite, setVisibilite, Slog}) {
    const [Edit, setEdit] = useState(false);
    const [NewVisibilite, setNewVisibilite] = useState(Visibilite);

    const {t} = useTranslation();

    const {id} = useParams();

    const saveConditions = async () => {
        try {
            const UserId = localStorage.getItem("ID_Utulisateur");
            const userToken = Cookies.get("Token");

            const response = await axios.post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/updateVisibility",
                {
                    Slog: Slog,
                    ID_Proprietaire: UserId,
                    Visibilite: NewVisibilite,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setVisibilite(NewVisibilite);
                setEdit(false);
                toast.success(t("Msgs.Updatesuccessful", {text: t('Labels.Visibility')}));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setNewVisibilite(Visibilite);
    }, [Visibilite, Edit]);

    const Items = [
        {
            key: "Visible",
            label: t("Host.TypeOfBeinCloseOrPublic.Visible"),
            desc: t("Host.TypeOfBeinCloseOrPublic.PublicDesc"),
        },
        {
            key: "InVisible",
            label: t("Host.TypeOfBeinCloseOrPublic.InVisible"),
            desc: t("Host.TypeOfBeinCloseOrPublic.MasqueeDesc"),
        },
    ];

    return (
        <div className={`flex flex-col gap-5 w-full lg:w-[50%]`}>
            <div className="flex flex-col gap-2 px-3">
                <div className="inputs flex flex-col w-full gap-3">
                    <h1 className="font-semibold text-lg">{t("Labels.Visibility")}</h1>
                    {!Edit ? (
                        <div
                            className="flex-col lg:flex-row gap-2 w-full flex items-start lg:items-center justify-between">
                            <h2 className="text-left">{t(`Host.TypeOfBeinCloseOrPublic.${Visibilite}`)}</h2>
                            <button
                                className="font-semibold cursor-pointer text-sec"
                                onClick={() => setEdit(true)}
                            >
                                {t("Actions.Edit")}
                            </button>
                        </div>
                    ) : (
                        <div className="bg-white shadow-lg border-[1px] w-fit rounded-md flex flex-col gap-3 p-3">
                            <div className="w-full items-center flex flex-col gap-5">
                                <div className="pt-5 flex flex-col gap-5">
                                    {Items.map((item) => (
                                        <div
                                            key={item.key}
                                            onClick={() => setNewVisibilite(item.key)}
                                            className={`z-0 travelType ${
                                                NewVisibilite === item.key ? "border-sec" : ""
                                            } py-5 px-5 cursor-pointer border-solid border-[1px] rounded-md hover:z-30 group relative w-full flex flex-col`}
                                        >
                                            <label
                                                className="-20 flex cursor-pointer font-semibold flex-row items-center justify-between w-full">
                                                <p>{item.label}</p>
                                                <input
                                                    className="accent-sec h-[1rem] w-[1rem]"
                                                    type="radio"
                                                    name="options"
                                                    id={item.key}
                                                    checked={NewVisibilite === item.key}
                                                    readOnly
                                                />
                                            </label>
                                            <div
                                                className="w-full pt-5 px-5 text-gray-400 text-sm bg-white duration-150">
                                                {item.desc}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="border-t-[1px] pt-2 flex flex-row justify-between">
                                <button
                                    onClick={() => setEdit(false)}
                                    className="px-3 p-2 text-gray-400 border-[1px] rounded-md font-medium"
                                >
                                    {t("Actions.Cancel")}
                                </button>
                                <button
                                    onClick={saveConditions}
                                    className="px-3 p-2 bg-sec text-white rounded-md font-medium"
                                >
                                    {t("Actions.Save")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateTypeOfBeinMorP;