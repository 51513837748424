import React, {useState, useRef} from "react";
import GrayLine from "../ui/GrayLine";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import {useClickOutside} from "../../hooks/UseClickOutSide";

function MobImage({id, img, makeImageFirst, handleDeleteImg}) {
    const [isOptionOpen, setIsOptionOpen] = useState(false);
    const OptionRef = useRef();
    useClickOutside(OptionRef, () => setIsOptionOpen(false));

    return (
        <div ref={OptionRef} key={id} className="w-full relative">
            <img className="w-full h-[7rem] object-cover" src={img} alt="superhost"/>
            <span
                onClick={() => setIsOptionOpen(!isOptionOpen)}
                className=" absolute text-black flex items-center justify-center top-2 right-2 h-[1.6rem] w-[1.6rem] bg-white rounded-[50%]  "
            >
        <MoreVertOutlinedIcon className=" scale-75"/>
      </span>

            {isOptionOpen && (
                <div
                    onClick={() => setIsOptionOpen(!isOptionOpen)}
                    className="  flex flex-col  gap-1 px-1 rounded-md shadow-lg py-1 bg-white text-sm   absolute top-6 right-6 "
                >
          <span
              onClick={() => handleDeleteImg(id)}
              className="  px-1 gap-1 bg-white text-black   flex items-center justify-center rounded-[50%]  "
          >
            <DeleteOutlineOutlinedIcon className=" scale-75"/>
            Delete
          </span>
                    <GrayLine/>
                    <button
                        className=" flex items-center justify-center gap-1 px-1 top-0 left-0 z-30"
                        onClick={() => makeImageFirst(id)}
                    >
                        <StarOutlinedIcon className=" scale-75"/>
                        Cover
                    </button>
                </div>
            )}
        </div>
    );
}

export default MobImage;
