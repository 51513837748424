import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import logo from "../assest/img/miniLogo.svg";
import amiricanEx from "../assest/img/american-express.png";
import visa from "../assest/img/visa (1).png";
import mastercard from "../assest/img/mastercard.png";
import jcb from "../assest/img/jcb.png";
import GrayLine from "./ui/GrayLine";

function Footer() {
    const {t} = useTranslation();
    const location = useLocation();
    const year = new Date().getFullYear();

    const socials = [
        {
            link: "https://www.facebook.com/superhosttunisie",
            icon: <FacebookOutlinedIcon/>,
        },
        {
            link: "https://www.instagram.com/superhost.tunisia",
            icon: <InstagramIcon/>,
        },
        // Uncomment and update if needed
        // {
        //   link: "https://twitter.com/",
        //   icon: <TwitterIcon />,
        // },
        // {
        //   link: "https://pinterest.com/",
        //   icon: <PinterestIcon />,
        // },
    ];

    const paymentMethods = [
        {logo: visa},
        {logo: mastercard},
        {logo: amiricanEx},
        {logo: jcb},
    ];

    const footerSections = [
        {
            title: t("Footer.Support"),
            links: [
                {text: t("Footer.PrivacyPolicy"), link: "/privacy-policy"},
                {text: t("Footer.TermsOfUse"), link: "/terms-of-use"},
                {text: t("Footer.ContactChannels"), link: null},
            ],
        },
        {
            title: t("Footer.Support"),
            links: [
                {text: t("Footer.OurStory"), link: null},
                {text: t("Footer.TravelBlog"), link: null},
                {text: t("Footer.WorkWithUs"), link: null},
                {text: t("Footer.BeOurPartner"), link: null},
            ],
        },
    ];

    const hiddenPaths = ["/dashboard", "/payment", "/auth", '/reservation-details'];
    const isHiddenPage = hiddenPaths.some((path) =>
        location.pathname.includes(path)
    );

    if (isHiddenPage) return null;

    return (
        <footer
            className="text-white px-[1rem] lg:px-[14%] w-full flex flex-col justify-start py-[4rem] md:py-[1rem] md:pt-[8rem] relative bg-main">
            <div className="flex gap-[3rem] md:gap-0 text-lg flex-col-reverse lg:flex-row justify-between">
                <div className="w-full text-[.9rem] md:w-[50%] flex flex-col gap-[2.5rem]">
                    <picture>
                        <source type="image/webp" srcSet={logo}/>
                        <img src={logo} alt="SuperHost Logo"/>
                    </picture>
                    <p className="w-full md:w-[80%]">{t("Footer.MoreVariations")}</p>
                    <div className="flex flex-row items-center gap-[1.8rem]">
                        {socials.map((item, index) => (
                            <Link
                                to={item.link}
                                target="_blank"
                                key={index}
                                className="hover:text-sec text-white duration-150"
                            >
                                {item.icon}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="flex justify-around md:gap-0 gap-[3rem] flex-col md:flex-row w-full">
                    {footerSections.map((section, index) => (
                        <div key={index} className="flex flex-col gap-[1.8rem]">
                            <h1 className="text-[1rem] font-bold">{section.title}</h1>
                            <div className="flex text-[.9rem] flex-col gap-[.9rem]">
                                {section.links.map((link, linkIndex) => (
                                    <Link
                                        className="text-[#F5F5F5] hover:text-sec"
                                        key={linkIndex}
                                        to={link.link}
                                    >
                                        {link.text}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className="flex flex-col text-[.9rem] gap-[1.8rem]">
                        <h4 className="font-bold">Contact Info</h4>
                        <div className="flex flex-col gap-[.9rem]">
                            <a
                                target="_blank"
                                href={`https://www.google.com/maps/search/?api=1&query=36.802330,10.101920`}
                            >
                                1er Étage M14-15 <br/>
                                Millenium Center, Tunis 2046
                            </a>
                            <a href="tel:+21627026060">+216 27026060</a>
                            <p className="hover:text-sec">
                                <a href="mailto:contact@superhost.com.tn">
                                    contact@superhost.com.tn
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="lg:flex hidden w-full pt-[5rem] flex-col">
                <GrayLine className="opacity-15"/>
                <div className="text-gray-400 text-lg flex w-full justify-between py-[1.95rem]">
                    <h3 className="text-[.9rem]">
                        © {year} SuperHost All Rights Reserved.
                    </h3>
                    <div className="flex gap-4">
                        {paymentMethods.map((method, index) => (
                            <Link
                                key={index}
                                className="cursor-pointer hover:opacity-85"
                                to="#"
                            >
                                <picture>
                                    <source type="image/webp" srcSet={method.logo}/>
                                    <img
                                        className="w-[70%]"
                                        src={method.logo}
                                        alt="SuperHost Tunisia"
                                    />
                                </picture>

                            </Link>
                        ))}
                    </div>
                </div>
            </div>

            {/* Uncomment if you want to add the big logo */}
            {/* <img
        style={{ mixBlendMode: "screen" }}
        className="absolute scale-[1] w-full -top-[2.5rem] md:-top-[10rem] right-[50%] transform translate-x-[50%]"
        src={superHost}
        alt="SuperHost Background"
      /> */}
        </footer>
    );
}

export default Footer;
