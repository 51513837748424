import React, {useState, useEffect, useContext} from "react";
import AddReductions from "../BecomeHost/AddReductions";
import GrayLine from "../ui/GrayLine";
import UpdatePrice from "./Calender/UpdatePrice";
import UpdateMenage from "./Calender/UpdateMenage";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {Usercontext} from "../../context/Usercontexte";
import LoadingBtn from "../ui/LoadingBtn";

function Tarifs({
                    Prices,
                    Slog,
                    setPrices,
                    update,
                    Reductions,
                    setReductions,
                    setClose,
                }) {
    const [isUpdatePrice, setIsUpdatePrice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateMenage, setIsUpdateMenage] = useState(false);
    const [NewReductions, setNewReductions] = useState({
        re1: {
            value: 20,
            isChecked: false,
        },
        re2: {
            value: 20,
            isChecked: false,
        },
    });

    const {t} = useTranslation();

    const {id} = useParams();
    useEffect(() => {
        setNewReductions(Reductions);
    }, [Reductions]);

    async function UpdateReductions() {
        setIsLoading(true);
        const userToken = Cookies.get("Token");
        try {
            const response = await axios.post(
                `https://visiontunisie.com/api/v1/GestionDesBiens/updateReductionByIdBien`,
                {
                    Slog: Slog,
                    // ReductionTroisJours: 1,
                    ReductionHebdomadaire: NewReductions.re1.isChecked
                        ? NewReductions.re1.value
                        : 0,
                    ReductionMensuel: NewReductions.re2.isChecked
                        ? NewReductions.re2.value
                        : 0,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            toast.success(
                t("Msgs.Updatesuccessful", {text: t("Host.Reductions.Discounts")})
            );
            setIsLoading(false);
            // setIsOpen(false);
            setReductions(NewReductions);
            return response?.data;
        } catch (error) {
            setIsLoading(false);
            throw new Error(`Error fetching geocoding data: ${error.message}`);
        }
    }

    const handleReductionsInputChange = (key, value) => {
        setNewReductions((prevReductions) => ({
            ...prevReductions,
            [key]: {
                ...prevReductions[key],
                value: value,
            },
        }));
    };

    const handleCheckboxChange = (key, isChecked) => {
        setNewReductions((prevReductions) => ({
            ...prevReductions,
            [key]: {
                ...prevReductions[key],
                isChecked: isChecked,
            },
        }));
    };

    const handleInputChange = (key, value) => {
        setPrices((prevPrices) => ({
            ...prevPrices,
            [key]: value,
        }));
    };

    return (
        <div className="w-full relative items-center flex flex-col gap-5 pb-20">
            <div
                className={`inputs  w-full ${
                    update ? "w-full" : "lg:w-[60%]"
                }  flex flex-col gap-5`}
            >
                <h3 className="travelType text-lg font-semibold">
                    {" "}
                    {t("Labels.PricePerNight")}{" "}
                </h3>

                {/* price */}
                <div
                    onClick={() => setIsUpdatePrice(true)}
                    className="travelType cursor-pointer py-2  w-full flex flex-row justify-between px-2 h-[3.7rem] border-[2px] rounded-lg"
                >
                    <div className="flex flex-row gap-3 items-center justify-center ">
                        <h4 className="px-2 bg-white font-semibold text-lg    border-none ">
                            {Prices.Prix}
                        </h4>

                        <span>/{t("Labels.Night")}</span>
                    </div>
                    <div
                        className="flex-row px-5 items-center justify-between cursor-pointer flex w-[25%]  md:w-[20%] h-full  border-solid border-[1px] rounded-md">
                        TND
                    </div>
                </div>

                {/*  Cleaning Fees */}
                <h2 className="travelType text-lg font-semibold">
                    {" "}
                    {t("Host.Prices.CleaningFee")}
                </h2>
                <div
                    onClick={() => setIsUpdateMenage(true)}
                    className="travelType py-2 cursor-pointer  w-full flex flex-row justify-between px-2 h-[3.7rem] border-[2px] rounded-lg"
                >
                    <div className="flex flex-row gap-3 items-center">
                        <p className="px-2 bg-white font-semibold text-lg border-none ">
                            {Prices.menage}
                        </p>
                        <span>/{t("Labels.Stay")}</span>
                    </div>
                    <div
                        className="flex-row px-5 items-center justify-between cursor-pointer flex w-[25%]  md:w-[20%] h-full  border-solid border-[1px] rounded-md">
                        TND
                    </div>
                </div>
            </div>

            <div className="inputs w-full    flex  flex-col gap-5">
                <h2 className="travelType text-lg font-semibold">
                    {t("Labels.Discounts")}
                </h2>

                <label
                    htmlFor="Re7jr"
                    className="travelType flex cursor-pointer flex-row w-full border-solid border-[1px] rounded-md justify-between items-start  h-full  px-5 py-4  "
                >
                    <div className=" flex flex-col gap-3">
                        <h2 className="   font-semibold">
                            {t("Host.Reductions.WeeklyDiscount")}
                        </h2>
                        <p className=" text-sm text-gray-400">
                            {t("Host.Reductions.ForBookingsOfNumNightsOrMore", {num: 7})}
                        </p>
                        <GrayLine/>
                        <div className=" flex flex-col gap-2">
                            <h2 className=" text-sm font-semibold">
                                {t("Host.Reductions.EnterYourPercentageDiscount")}
                            </h2>
                            <input
                                name="re1"
                                onChange={(e) =>
                                    handleReductionsInputChange("re1", e.target.value)
                                }
                                value={`${NewReductions?.re1?.value}`}
                                type="number"
                                className={`px-3 ${
                                    NewReductions?.re1?.isChecked ? "border-sec text-sec" : ""
                                }  font-semibold  w-full h-[3.4rem] border-solid  border-[1px] rounded-md`}
                            />
                        </div>
                    </div>
                    <input
                        value={`${NewReductions?.re1?.isChecked}`}
                        onChange={(e) => handleCheckboxChange("re1", e.target.checked)}
                        className="accent-pink-500 mt-4 h-[1rem] w-[1rem]  "
                        type="checkbox"
                        checked={NewReductions?.re1?.isChecked}
                        name="Re7jr"
                        id="Re7jr"
                    />
                </label>

                <label
                    htmlFor="Re25jr"
                    className="travelType flex cursor-pointer flex-row w-full border-solid border-[1px] rounded-md justify-between items-start  h-full  px-5 py-4  "
                >
                    <div className=" flex flex-col gap-3">
                        <h2 className="  font-semibold">
                            {t("Host.Reductions.DiscountPerMonth")}{" "}
                        </h2>
                        <p className=" text-sm text-gray-400">
                            {t("Host.Reductions.ForBookingsOfNumNightsOrMore", {num: 30})}
                        </p>
                        <GrayLine/>
                        <div className=" flex flex-col gap-2">
                            <h2 className=" text-sm font-semibold">
                                {t("Host.Reductions.EnterYourPercentageDiscount")}
                            </h2>
                            <input
                                name="re2"
                                onChange={(e) =>
                                    handleReductionsInputChange("re2", e.target.value)
                                }
                                value={`${NewReductions?.re2?.value}`}
                                type="number"
                                className={`px-3 ${
                                    NewReductions?.re2?.isChecked ? "border-sec text-sec" : ""
                                }  font-semibold  w-full h-[3.4rem] border-solid  border-[1px] rounded-md`}
                            />
                        </div>
                    </div>
                    <input
                        value={`${NewReductions?.re2?.isChecked}`}
                        onChange={(e) => handleCheckboxChange("re2", e.target.checked)}
                        className="accent-pink-500 mt-4 h-[1rem] w-[1rem]  "
                        type="checkbox"
                        checked={NewReductions?.re2?.isChecked}
                        name="Re25jr"
                        id="Re25jr"
                    />
                </label>
            </div>
            <div
                className="   lg:relative absolute bottom-0 right-0  lg:border-0  lg:px-0 px-4 border-t-[1px]  py-3 flex w-full justify-between gap-4  font-semibold ">
                <button
                    onClick={UpdateReductions}
                    className=" relative w-full py-3 rounded-md   text-white bg-main "
                >
                    {t("Actions.Save")}
                    <LoadingBtn scale={"scale-[.15] "} isLoading={isLoading}/>
                </button>
            </div>
            <UpdatePrice
                Slog={Slog}
                setPrices={setPrices}
                Prix={Prices?.Prix}
                isOpen={isUpdatePrice}
                setIsOpen={setIsUpdatePrice}
            />
            <UpdateMenage
                Slog={Slog}
                setPrices={setPrices}
                menage={Prices?.menage}
                isOpen={isUpdateMenage}
                setIsOpen={setIsUpdateMenage}
            />
        </div>
    );
}

export default Tarifs;
